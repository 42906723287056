<template>
	<div />
</template>

<script lang="ts" setup>
	// @todo This component shows PWA info that's useful for us while we're building
	// the app. Eventually it can either go or become much simpler. There's not much
	// left now, so the remaining code could probably be moved up into App.vue, but
	// equally it is quite well packaged here.

	import { usePwaStore } from "@/stores/pwa";
	import { useAuthStore } from "@/stores/auth";
	import { watch } from "vue";

	const pwaStore = usePwaStore();
	const authStore = useAuthStore();

	watch(() => pwaStore.onlineAndConnected, (newVal) => {
		if (newVal) {
			console.log("PwaStatus: Now online");
			console.log("PwaStatus: Syncing user...");
			authStore.syncCurrentUser();
			console.log("PwaStatus: Synced!");

		} else {
			console.log("PwaStatus: Now offline");
		}
	});
</script>
