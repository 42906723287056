<template>
	<Alert class="mb-4"/>
	<UiForm id="login-form" @submit.prevent="onFormSubmit">
		<TextInput v-model="form.email" type="email" :label="trans('users.labels.email')" name="email" autocomplete="email" class="mb-2"/>
		<TextInput v-model="form.password" type="password" :label="trans('users.labels.password')" name="password" class="mb-4"/>
		<div class="text-center">
			<UiButton type="submit" :label="trans('global.buttons.login')" @click="onFormSubmit"/>
		</div>
	</UiForm>
</template>

<script>
	import { trans } from "@/helpers/i18n";
	import { reactive, defineComponent } from "vue";
	import { useAuthStore } from "@/stores/auth";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Alert from "@/views/components/Alert.vue";
	import UiForm from "@/views/components/UiForm.vue";

	export default defineComponent({
		name: "LoginForm",
		components: {
			UiForm,
			Alert,
			UiButton,
			TextInput
		},
		emits: ["error"],
		setup (props, { emit }) {
			const authStore = useAuthStore();
			const form = reactive({
				email: null,
				password: null
			});

			function onFormSubmit () {
				const payload = {
					email: form.email,
					password: form.password
				};
				authStore.login(payload);
			}

			return {
				onFormSubmit,
				form,
				trans
			};
		}
	});
</script>
