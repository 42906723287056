<template>
	<Panel :title="trans('users.labels.password_settings')">
		<form @submit.prevent="onFormSubmit">
			<TextInput v-model="form.currentPassword" type="password" :required="true" :label="trans('users.labels.current_password')" name="current-password" class="mb-4"/>
			<hr>
			<TextInput v-model="form.password" type="password" :required="true" :label="trans('users.labels.new_password')" name="password" class="mb-4"/>
			<TextInput v-model="form.passwordConfirm" type="password" :required="true" :label="trans('users.labels.confirm_password')" name="password-confirm" class="mb-4"/>

			<UiButton type="submit" :label="trans('global.buttons.update')" @click="onFormSubmit"/>
		</form>
	</Panel>
</template>

<script>

	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { reactive, defineComponent } from "vue";
	import { useAlertStore } from "@/stores";
	import { getResponseError } from "@/helpers/api";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Panel from "@/views/components/Panel";

	export default defineComponent({
		components: {
			Panel,
			UiButton,
			TextInput
		},
		setup () {

			const authService = new AuthService();
			const alertStore = useAlertStore();
			const form = reactive({
				currentPassword: null,
				password: null,
				passwordConfirm: null
			});

			function onFormSubmit () {
				const payload = {
					current_password: form.currentPassword,
					password: form.password,
					password_confirmation: form.passwordConfirm
				};
				authService.updatePassword(payload)
					.then((response) => (alertStore.success(trans("global.phrases.password_updated"))))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				onFormSubmit,
				form,
				trans
			};
		}
	});
</script>
