import BaseService from "@/services/BaseService";
import axios from "@/plugins/axios";

export default class SearchService extends BaseService {

	constructor (entity: any) {
		super();
		this.url = "/api/" + entity;
		this.setupAPI(axios.defaults.baseURL);
	}

	public begin (phrase: any, page: any, perPage: any) {
		return this.get(this.url + `/?search=${phrase}&per_page=${perPage}&page=${page}`);
	}

}

