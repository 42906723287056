<template>
	<div v-if="alertStore.hasAlert()">
		<div v-if="alertStore" :class="wrapperClass" class="position-relative pt-3" role="alert">
			<template v-if="alertStore.isError()">
				<template v-if="alertStore.hasMultiple()">
					<strong class="fw-bold me-2">{{ trans('global.phrases.argh') }}</strong>
					<span class="block sm:inline">{{ trans('global.phrases.fix_errors') }}</span>
					<ul key="error-list" class="mt-2 list-disc pl-5">
						<li v-for="key in errorKeys" :key="key">
							{{ getMessageError(key) }}
						</li>
					</ul>
					<span class="position-absolute top-0 end-0 px-2 py-2 cursor-pointer" @click="removeMessage">
						<i class="bi-x my-2"/>
					</span>
				</template>
				<template v-else>
					<strong class="fw-bold me-2">{{ trans('global.phrases.argh') }}</strong>
					<span class="block sm:inline">{{ Array.isArray(alertStore.messages[0]) ? alertStore.messages[0][0] : alertStore.messages[0] }}</span>
					<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage">
						<i class="bi-x-lg me-2"/>
					</span>
				</template>
			</template>
			<template v-else-if="alertStore.isSuccess()">
				<strong class="fw-bold me-2">{{ trans('global.phrases.success') }}</strong>
				<span class="block sm:inline">{{ Array.isArray(alertStore.messages[0]) ? alertStore.messages[0][0] : alertStore.messages[0] }}</span>
				<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage">
					<i class="bi-x me-2"/>
				</span>
			</template>
			<template v-else-if="alertStore.isWarn()">
				<strong class="fw-bold me-2">{{ trans('global.phrases.warning') }}</strong>
				<span class="block sm:inline">{{ Array.isArray(alertStore.messages[0]) ? alertStore.messages[0][0] : alertStore.messages[0] }}</span>
				<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage">
					<i class="bi-x me-2"/>
				</span>
			</template>
			<template v-else>
				<span class="block sm:inline">{{ Array.isArray(alertStore.messages[0]) ? alertStore.messages[0][0] : alertStore.messages[0] }}</span>
				<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage">
					<i class="bi-x me-2"/>
				</span>
			</template>
		</div>
	</div>
</template>

<script>

	import { computed, defineComponent, ref } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAlertStore } from "@/stores";

	export default defineComponent({
		components: {  },
		inheritAttrs: true,
		setup (props, { emit }) {

			const alertStore = useAlertStore();
			const wrapperClass = computed(() => {
				if (alertStore.isSuccess()) {
					return "border px-4 py-4 rounded relative text-sm text-success bg-success-subtle border border-success p-2";
				}
				else if (alertStore.isWarn()) {
					return "border px-4 py-3 rounded relative text-sm text-warning bg-warning-subtle border border-warning p-2";
				}
				else if (alertStore.isInfo()) {
					return "border px-4 py-3 rounded relative text-sm text-primary bg-info-subtle border border-primary p-2";
				}
				else {
					return "border px-4 py-3 rounded relative text-sm text-danger bg-danger-subtle border border-danger p-2";
				}
			});

			const errorKeys = computed(() => {
				if (!alertStore.messages || getType(alertStore.messages) === "string") {
					return null;
				}
				return Object.keys(alertStore.messages);
			});

			function getType (obj) {
				return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
			}

			function getMessageError (key) {
				return alertStore.messages[key][0];
			}

			function removeMessage () {
				alertStore.clear();
			}

			return {
				alertStore,
				wrapperClass,
				errorKeys,
				getType,
				getMessageError,
				removeMessage,
				trans
			};
		}
	});
</script>
