import { usePwaStore } from "@/stores/pwa";
import { registerSW } from "virtual:pwa-register";
import type { BeforeInstallPromptEvent } from "@/types/events";

export function createPwa () {
	// PWA setup - capture the install event and put it in the store (when available)
	// for the UI to use it later to ask the user to install the app. Does not work
	// on every browser.
	const pwaStore = usePwaStore();
	window.addEventListener("beforeinstallprompt", (event: BeforeInstallPromptEvent) => {
		pwaStore.event = event;
	});

	// PWA setup
	// - register the service worker after the document loads, to save
	// the browser and the SW competing to load resources on first page load.
	// - supply a callback if the service worker detects that the app needs a
	// refresh event. We can use this to prompt the user to update rather than
	// doing it automatically and potentially losing their offline data.  The
	// reload of the app resets // showRefresh back to false so we don't need to
	// take care of that.
	window.addEventListener("load", () => {
		const updateSW = registerSW({
			onNeedRefresh () {
				pwaStore.showRefresh = true;
			},
			onOfflineReady () {
				console.log("Offline ready!");
			}
		});
		pwaStore.updateSW = updateSW;
	});

	// Online/offline - add event handlers to track when the user goes on and
	// offline.
	window.addEventListener("offline", (e) => {
		pwaStore.offline();
	});
	window.addEventListener("online", (e) => {
		pwaStore.online();
	});

	// Work out if the user is both online AND cannected
	pwaStore.getOnlineAndConnected();
}
