import { defineStore } from "pinia";
import type { AlertStore } from "../types/stores";

export const useAlertStore = defineStore({
	id: "alert",
	state: (): AlertStore => {
		return {
			messages: [],
			type: null
		};
	},
	actions: {
		success (message: any) {
			this.clear();
			this.push(message);
			this.type = "success";
		},
		error (message: any) {
			this.clear();
			this.push(message);
			this.type = "error";
		},
		warn (message: any) {
			this.clear();
			this.push(message);
			this.type = "warn";
		},
		info (message: any) {
			this.clear();
			this.push(message);
			this.type = "info";
		},
		push (message: any) {
			if (Array.isArray(message) || (typeof message === "object" && message != null)) {
				for (const i in message) {
					this.messages.push(message[i]);
				}
			} else {
				this.messages.push(message);
			}
		},
		clear () {
			this.messages = [];
			this.type = null;
		},
		isSuccess () {
			return this.type === "success";
		},
		isError () {
			return this.type === "error";
		},
		isWarn () {
			return this.type === "warn";
		},
		isInfo () {
			return this.type === "info";
		},
		hasAlert () {
			return this.messages.length > 0 && this.type != null;
		},
		hasMultiple () {
			return (Array.isArray(this.messages.length) && this.messages.length > 1) || ((typeof this.messages === "object" && this.messages != null)  && Object.keys(this.messages).length > 1);
		}
	}

});
