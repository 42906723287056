<template>
	<Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onPageAction">
		<template v-if="page.toggleFilters" #filters>
			<Filters @clear="onFiltersClear">
				<FiltersRow>
					<FiltersCol>
						<TextInput v-model="mainQuery.filters.first_name.value" name="first_name" :label="trans('users.labels.first_name')"/>
					</FiltersCol>
					<FiltersCol>
						<TextInput v-model="mainQuery.filters.last_name.value" name="last_name" :label="trans('users.labels.last_name')"/>
					</FiltersCol>
					<FiltersCol>
						<TextInput v-model="mainQuery.filters.email.value" name="email" type="email" :label="trans('users.labels.email')"/>
					</FiltersCol>
					<FiltersCol>
						<Dropdown v-model="mainQuery.filters.role.value" name="role" server="roles/search" :multiple="true" :label="trans('users.labels.role')"/>
					</FiltersCol>
				</FiltersRow>
			</Filters>
		</template>

		<template #body>
			<UiTable v-if="table" :id="page.id" :headers="table.headers" :sorting="table.sorting" :actions="table.actions" :records="table.records" :pagination="table.pagination" :is-loading="table.loading" @page-changed="onTablePageChange" @action="onTableAction" @sort="onTableSort">
				<template #content-id="props">
					<div class="flex items-center">
						<div class="flex-shrink-0 h-10 w-10">
							<img v-if="props.item.avatar_url" :src="props.item.avatar_url" class="h-10 w-10 rounded-circle" alt="">
							<Avatar v-else class="w-10 h-10 text-gray-400 rounded-circle"/>
						</div>
						<div class="ml-4">
							<div class="text-sm font-medium text-gray-900">
								{{ props.item.full_name }}
							</div>
							<div class="text-sm text-gray-500">
								{{ trans('users.labels.id') + ': ' + props.item.id }}
							</div>
						</div>
					</div>
				</template>
				<template #content-status="props">
					<span v-if="props.item.email_verified_at" class="px-2 inline-flex text-xs leading-5 fw-semibold rounded-circle bg-green-100 text-green-800" v-html="trans('users.status.verified')"/>
					<span v-else class="px-2 inline-flex text-xs leading-5 fw-semibold rounded-circle bg-red-100 text-red-800" v-html="trans('users.status.not_verified')"/>
				</template>
				<template #content-role="props">
					{{
						props.item.roles.map((entry) => {
							return entry.title
						}).join(', ')
					}}
				</template>
			</UiTable>
		</template>
	</Page>
</template>

<script>

	import { trans } from "@/helpers/i18n";
	import UserService from "@/services/UserService";
	import { watch, onMounted, defineComponent, reactive, ref } from "vue";
	import { getResponseError, prepareQuery } from "@/helpers/api";
	import { toUrl } from "@/helpers/routing";
	import { useAlertStore } from "@/stores";
	import alertHelpers from "@/helpers/alert";
	import Page from "@/views/layouts/Page";
	import UiTable from "@/views/components/UiTable.vue";
	import Avatar from "@/views/components/icons/Avatar";
	import Filters from "@/views/components/filters/Filters";
	import FiltersRow from "@/views/components/filters/FiltersRow";
	import FiltersCol from "@/views/components/filters/FiltersCol";
	import TextInput from "@/views/components/input/TextInput";
	import Dropdown from "@/views/components/input/Dropdown";

	export default defineComponent({
		components: {
			Dropdown,
			TextInput,
			FiltersCol,
			FiltersRow,
			Filters,
			UiTable,
			Avatar
		},
		setup () {
			const service = new UserService();
			const alertStore = useAlertStore();
			const mainQuery = reactive({
				page: 1,
				search: "",
				sort: "",
				filters: {
					first_name: {
						value: "",
						comparison: "="
					},
					last_name: {
						value: "",
						comparison: "="
					},
					role: {
						value: "",
						comparison: "="
					},
					email: {
						value: "",
						comparison: "="
					}
				}
			});

			const page = reactive({
				id: "list_users",
				title: trans("global.pages.users"),
				breadcrumbs: [
					{
						name: trans("global.pages.users"),
						to: toUrl("/users"),
						active: true
					}
				],
				actions: [
					{
						id: "filters",
						name: trans("global.buttons.filters"),
						icon: "bi-funnel",
						theme: "outline"
					},
					{
						id: "new",
						name: trans("global.buttons.add_new"),
						icon: "bi-plus",
						to: toUrl("/users/create")
					}
				],
				toggleFilters: false
			});

			const table = reactive({
				headers: {
					id: trans("users.labels.id_pound"),
					first_name: trans("users.labels.first_name"),
					last_name: trans("users.labels.last_name"),
					email: trans("users.labels.email"),
					status: trans("users.labels.status"),
					role: trans("users.labels.role")
				},
				sorting: {
					first_name: true,
					last_name: true
				},
				pagination: {
					meta: null,
					links: null
				},
				actions: {
					edit: {
						id: "edit",
						name: trans("global.actions.edit"),
						icon: "bi-pencil-square",
						showName: false,
						to: toUrl("/users/{id}/edit")
					},
					delete: {
						id: "delete",
						name: trans("global.actions.delete"),
						icon: "bi-trash",
						showName: false,
						danger: true
					}
				},
				loading: false,
				records: null
			});

			function onTableSort (params) {
				mainQuery.sort = params;
			}

			function onTablePageChange (page) {
				mainQuery.page = page;
			}

			function onTableAction (params) {
				switch (params.action.id) {
				case "delete":
					alertHelpers.confirmDanger(function () {
						service.delete(params.item.id).then(function (response) {
							fetchPage(mainQuery);
						});
					});
					break;
				}
			}

			function onPageAction (params) {
				switch (params.action.id) {
				case "filters":
					page.toggleFilters = !page.toggleFilters;
					break;
				}
			}

			function onFiltersClear () {
				let clonedFilters = mainQuery.filters;
				for (let key in clonedFilters) {
					clonedFilters[key].value = "";
				}
				mainQuery.filters = clonedFilters;
			}

			function fetchPage (params) {
				table.records = [];
				table.loading = true;
				let query = prepareQuery(params);
				service
					.index(query)
					.then((response) => {
						table.records = response.data.data;
						table.pagination.meta = response.data.meta;
						table.pagination.links = response.data.links;
						table.loading = false;
					})
					.catch((error) => {
						alertStore.error(getResponseError(error));
						table.loading = false;
					});
			}

			watch(mainQuery, (newTableState) => {
				fetchPage(mainQuery);
			});

			onMounted(() => {
				fetchPage(mainQuery);
			});

			return {
				trans,
				page,
				table,
				onTablePageChange,
				onTableAction,
				onTableSort,
				onPageAction,
				onFiltersClear,
				mainQuery
			};

		}
	});
</script>
