import router from "@/router";
import { defineStore } from "pinia";
import { getResponseError } from "@/helpers/api";
import { useAlertStore } from "@/stores/alert";
import SurveyService from "@/services/SurveyService";
import AuthService from "@/services/AuthService";
// import UserService from "@/services/UserService";
import { trans } from "@/helpers/i18n";
import type { SurveyStore } from "@/types/stores";
// import type { Survey } from "@/types/survey";
import { scoreSurvey_WCA, getWarnings, getErrors } from "@/modules/woodlandSurvey";

import type { WoodlandSurvey } from "@/types/models";

export const useSurveyStore = defineStore("survey", {
	state: (): SurveyStore => {
		return {
			activeSurvey: null,
			resultsSurvey: {}
		};
	},
	actions: {
		async index () {
			const surveyService = new SurveyService();
			const response = await surveyService.index();
			return response.data.data;
		},
		canSubmit (survey: any) {
			return !this.submitError(survey);
		},
		submitError (survey: any) {
			return getErrors(survey).length > 0;
		},
		submitWarning (survey: any) {
			return getWarnings(survey).length > 0;
		},
		submitMessage (survey: any) {
			let message = "";
			if (this.submitError(survey)) message += "error ";
			if (this.submitWarning(survey)) message += "warning ";
			return message;
		},
		/**
		 * Create a survey
		 * @todo Placeholder for now
		 */
		async create (survey: WoodlandSurvey): Promise<WoodlandSurvey> {
			return survey;
		},
		/**
		 * Update a survey
		 * @todo Placeholder for now
		 */
		async update (survey: WoodlandSurvey): Promise<WoodlandSurvey> {
			return survey;
		}
		//         async save(payload: any) {
		//             const surveyService = new SurveyService();
		//             const alertStore = useAlertStore();
		//             try {
		//                 // const response = await SurveyService.save(payload);
		//                 // this.survey = response.data.survey;
		//                 // alertStore.clear();
		//                 // await router.push("/panel/dashboard");
		//                 // await this.getCurrentUser();
		//             } catch (error) {
		//                 alertStore.error(getResponseError(error));
		//             }
		//         },
		//         async submit(payload: any) {
		// 			this.save(payload);
		//             const surveyService = new SurveyService();
		//             const alertStore = useAlertStore();
		//             try {
		//                 // const response = await SurveyService.submit(payload);
		//                 // this.status = response.data.status;
		//                 // alertStore.clear();
		//                 // await router.push("/panel/dashboard");
		//                 // await this.getCurrentUser();
		//             } catch (error) {
		//                 alertStore.error(getResponseError(error));
		//             }
		//         },
		// 		async clear(payload: any) {
		// 			console.log("write code");

		// 		},
	},
	getters: {
		//         isComplete: (state) => {
		//             return state.survey ? state.survey.complete : false;
		//         },
	},
	persist: true
});
