<template>
	<Auth>
		<h2 class="font-bold text-2xl text-[#002D74]">
			{{ trans('global.pages.forgot_password') }}
		</h2>
		<p class="text-sm mt-4 mb-4 text-[#002D74]">
			{{ trans('global.phrases.forgot_password_desc') }}(
			<router-link to="/login" class="text-sm base-link">
				{{ trans('global.phrases.forgot_password_login') }}
			</router-link>)
		</p>
		<ForgotPasswordForm/>

		<div class="mt-3 text-sm py-4 text-[#002D74]">
			<p>{{ trans('global.phrases.register_ask') }}</p>

			<div class="text-center">
				<router-link to="/register">
					<UiButton :label="trans('global.buttons.register')"/>
				</router-link>
			</div>
		</div>
	</Auth>
</template>

<script>
	import { default as ForgotPasswordForm } from "@/views/pages/auth/forgot-password/Form";
	import { trans } from "@/helpers/i18n";
	import Auth from "@/views/layouts/Auth";
	import UiButton from "@/views/components/input/UiButton.vue";

	export default {
		name: "ForgotPassword",
		components: {
			Auth,
			ForgotPasswordForm,
			UiButton
		},
		setup () {
			return {
				trans
			};
		}
	};
</script>
