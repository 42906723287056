<template>
	<Page class="fade-in mt-3">
		<template #body>
			<div class="container ">
				<div class="grey-bg container-fluid">
					<h3>Terms and Conditions</h3>

					<ul class="list-group">
						<li class="list-group-item">
							Information supplied by users of the Woodland Condition Assessment application (app) is
							managed by Sylva Foundation.
						</li>
						<li class="list-group-item">
							Excluding personal data, information from the Woodland Condition Assessment is routinely
							passed to government agencies (Forestry Commission and Natural England) on request at a
							low-resolution of 10km2 so that individual woodlands cannot be identified. This information
							provides government agencies with a strategic overview of woodland condition at landscape,
							regional and sub regional scales.
						</li>
						<li class="list-group-item">
							In addition, users of the app may optionally choose to share accurate location data with
							government agencies to support more detailed strategic oversight, for example providing
							government agencies with information that could aid in monitoring the effectiveness of
							policies and incentives at a finer resolution. No personal data are shared if this option is
							checked.
						</li>
					</ul>

					<br>
					<h3>Sylva Foundation Online Service Terms and Services Privacy Notice</h3>

					<ul class="list-group">
						<li class="list-group-item">
							To read Sylva Foundation's Data Protection Policy and its
							Privacy Notice for Online Services, <a href="https://sylva.org.uk/privacy-policy/">click here</a>.
						</li>
					</ul>
				</div>
			</div>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	// import { defineComponent } from "vue";
	import Page from "@/views/layouts/Page.vue";

	import UiButton from "@/views/components/input/UiButton.vue";
	import { ref } from "vue";

	const isChecked = ref(true);

	function toggleSwitch () {
		// isChecked = !isChecked;
		console.log(isChecked.value);
	}
	function fadeOut () {
		// Set isVisible to false to trigger the fade out effect
		isChecked.value = false;
	}
</script>
<style>
.fade-enter-active {
  transition: opacity 0.5s ease;
  transition-delay: 0.25s;
}
.fade-leave-active {
  transition: opacity 0.25s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* input[type="checkbox"] {
  background: rgb(var(--fsc-ocean-blue));
  color: white;
} */
/* input[type="checkbox"]:checked {
  background: rgb(var(--fsc-sunshine-yellow));
  color: white;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: lightgray;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  color: white;
}

input[type="checkbox"]:after {
  content: ' ';
  position: relative;
  left: 40%;
  top: 20%;
  width: 15%;
  height: 40%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(50deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
} */
</style>
