<template>
	<div>
		<Alert class="mb-4"/>
		<UiForm id="reset-password-form" @submit.prevent="onFormSubmit">
			<!-- <TextInput v-model="form.email" type="email" :required="true" :label="trans('users.labels.email')" name="email" class="mb-2"/>
			<TextInput v-model="form.password" type="password" :required="true" :label="trans('users.labels.password')" name="password" class="mb-2"/>
			<TextInput v-model="form.passwordConfirm" type="password" :required="true" :label="trans('users.labels.confirm_password')" name="password-confirm" class="mb-4"/> -->

			<div class="mb-2">
				<label for="email" class="text-sm text-gray-500">{{ trans('users.labels.email') }}</label><br>
				<input id="email" v-model="form.email" type="email" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500">
			</div>
			<div class="mb-2">
				<label for="password" class="text-sm text-gray-500">{{ trans('users.labels.password') }}</label><br>
				<input id="password" v-model="form.password" type="password" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500">
			</div>
			<div class="mb-4">
				<label for="password-confirm" class="text-sm text-gray-500">{{ trans('users.labels.confirm_password') }}</label><br>
				<input id="password-confirm" v-model="form.passwordConfirm" type="password" class="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500">
			</div>
			<div class="text-center">
				<UiButton type="submit" :label="trans('global.buttons.submit')" @click="onFormSubmit"/>
			</div>
		</UiForm>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService";
	import { reactive, defineComponent } from "vue";
	import { useRoute } from "vue-router";
	import { trans } from "@/helpers/i18n";
	import { useAlertStore } from "@/stores";
	import { getResponseError } from "@/helpers/api";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Alert from "@/views/components/Alert";
	import UiForm from "@/views/components/UiForm.vue";

	export default defineComponent({
		name: "ResetPasswordForm",
		components: {
			UiForm,
			Alert,
			UiButton
		},
		setup () {

			const authService = new AuthService();
			const alertStore = useAlertStore();
			const route = useRoute();
			const form = reactive({
				email: null,
				password: null,
				passwordConfirm: null
			});

			function onFormSubmit () {
				const payload = {
					email: form.email,
					password: form.password,
					password_confirmation: form.passwordConfirm,
					token: route.query.token
				};
				authService.resetPassword(payload)
					.then((response) => (alertStore.success(response.data.message)))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				onFormSubmit,
				form,
				trans
			};
		}
	});
</script>
