<template>
	{{ label }}<span class="text-danger">{{ required ? "*" : "" }}</span>
	<br>
	<div v-for="(option, index) in selectOptions" :key="index" class="form-check">
		<input
			:id="option.id"
			v-model="option.checked"
			:checked="option.checked"
			class="form-check-input shadow-sm border border-gray-300"
			type="checkbox"
			:name="name"
			:title="name"
			:required="required"
			:disabled="disabled"
			:placeholder="placeholder"
			@change="updateModelValue(option)">
		<label class="form-check-label" :for="option.id">
			<a v-if="option.option.link" :href="option.option.link" target="_blank">{{ option.title }}</a>
			<span v-else>{{ option.title }}</span>
		</label>
	</div>
</template>

<script >

	import { computed, defineComponent, ref } from "vue";

	import SearchService from "@/services/SearchService";
	import { trans } from "@/helpers/i18n";

	export default defineComponent({
		components: {  },
		inheritAttrs: false,
		props: {
			class: String,
			style: [String, Object],
			name: {
				type: String,
				required: true
			},
			options: {
				required: false,
				type: Array
			},
			label: {
				type: String,
				default: ""
			},
			modelValue: {
				type: [Object, String]
			},
			showLabel: {
				type: Boolean,
				default: true
			},
			required: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: null
			},
			multiple: {
				type: [Boolean, String, Number],
				default: false
			},
			server: {
				type: String,
				default: null
			},
			serverPerPage: {
				type: Number,
				default: 5
			},
			serverSearchMinCharacters: {
				type: Number,
				default: 3
			}
		},
		emits: ["update:model-value", "input"],
		setup (props, { emit }) {
			// console.log(props.options);

			let isLoading = ref(false);

			const selectOptions = computed(() => {
				let val = [];
				for (let i in props.options) {
					if (typeof props.options[i] === "string") {
						val.push({ id: props.options[i], title: props.options[i], checked: props.options[i].id === String(modelValue) });
					}
					else if (typeof props.options[i] === "object") {
						val.push({
							id: props.options[i].id,
							title: props.options[i]?.title ? props.options[i]?.title : props.options[i]?.name,
							checked: props.options[i].id === String(props.modelValue),
							option: props.options[i] });
					} else {
						val.push(props.options[i]);
					}
				}
				return val;
			});

			function updateModelValue (option) {
				// option.checked = !option.checked;
				emit("update:model-value", option.option ? option.option : option, option.checked);
			}

			const value = computed({
				get () {
					return props.modelValue;
				},
				set (newValue) {
					emit("update:model-value", newValue);
					emit("input", value);
				}
			});

			function handleSearch (search) {
				if (!props.server) {
					return;
				}
				if (search.length < props.serverSearchMinCharacters) {
					return;
				}
				const service = new SearchService(props.server);
				isLoading.value = true;
				service.begin(search, 1, props.serverPerPage).then((response) => {
					selectOptionsArr.value = [];
					for (let i in response.data.data) {
						selectOptionsArr.value.push({ id: response.data.data[i].id, title: response.data.data[i].title });
					}
					isLoading.value = false;
				}).catch((error) => {
					isLoading.value = false;
				});
			}

			return {
				value,
				selectOptions,
				handleSearch,
				isLoading,
				trans,
				updateModelValue
			};
		}
	});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.multiselect__input {
    max-width: 300px !important;
    border: 0 !important;
}
.multiselect__input:focus, .multiselect__input:active, .multiselect__input:hover {
    border: 0 !important;
    outline: none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
}
</style>
