<template>
	<div>
		<Alert class="mb-4"/>
		<UiForm id="forgot-password-form" @submit.prevent="onFormSubmit">
			<TextInput v-model="form.email" type="email" :required="true" :label="trans('users.labels.email')" name="email" autocomplete="email" class="mb-4"/>
			<div class="text-center">
				<UiButton type="submit" :label="trans('global.buttons.send')" @click="onFormSubmit"/>
			</div>
		</UiForm>
	</div>
</template>

<script>
	import AuthService from "@/services/AuthService";
	import { reactive, defineComponent } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAlertStore } from "@/stores";
	import { getResponseError } from "@/helpers/api";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput";
	import Alert from "@/views/components/Alert";
	import UiForm from "@/views/components/UiForm";

	export default defineComponent({
		name: "ForgotPasswordForm",
		components: {
			UiForm,
			Alert,
			UiButton,
			TextInput
		},
		setup () {

			const authService = new AuthService();
			const alertStore = useAlertStore();
			const form = reactive({
				email: null
			});

			function onFormSubmit () {
				authService.forgotPassword({ email: form.email })
					.then((response) => (alertStore.success(response.data.message)))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				onFormSubmit,
				form,
				trans
			};
		}
	});
</script>
