import ModelService from "@/services/ModelService";

export default class UserService extends ModelService {

	constructor () {
		super();
		this.url = "/users";
	}

	// public updateAvatar (id: any, payload: any) {
	// 	const formData = new FormData();
	// 	formData.append("avatar", payload.avatar);
	// 	formData.append("_method", "put");
	// 	// The endpoint /users/{id}/avatar is not currently implemented
	// 	return Promise.resolve(true);
	// 	// return this.post(`/users/${id}/avatar`, formData);
	// }

}
