<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4>Add User</h4>
				</div>
				<div class="card-body">
					<form @submit.prevent="create">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="form-group">
									<label>Title</label>
									<input v-model="user.first_name" type="text" class="form-control">
								</div>
							</div>
							<div class="col-12">
								<button type="submit" class="btn btn-primary">
									Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "@/plugins/axios";

	export default {
		name: "UserAdd",
		data () {
			return {
				user: {
					name: ""
					// description: ""
				}
			};
		},
		methods: {
			async create () {
				await axios.post(route("api.user.store"), this.user).then(response=>{
					this.$router.push({ name: "userList" });
				}).catch(error=>{
					console.log(error);
				});
			}
		}
	};
</script>
