<template>
	<Modal
		:id="elementId"
		:title="trans('users.labels.update_estate')"
		:has-form="true"
		@submit="onFormSubmit" @clear="onFormClear" @close="onFormClear">
		<UiForm
			:type="'update'"
			:data-bs-dismiss="validateForm()"
			@submit="onFormSubmit()"
			@clear="onFormClear"
			@close="onFormClear">
			<TextInput v-model="form.name" type="text" :required="true" :label="'Property ' + trans('users.labels.name')" name="name" class="mb-2" :maxlength="254" />
		</UiForm>
	</Modal>
</template>

<script lang="ts" setup>
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed, watch } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { v4 as uuidv4 } from "uuid";
	import axios from "@/plugins/axios";
	import { useEstateStore } from "@/stores/estate";

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const estateStore = useEstateStore();
	const form = reactive({
		name: ""
	});

	const props = defineProps<{
		estate: any;
		elementId:string;
	}>();

	watch(() => props.estate, (newVal) => {
		onFormClear();
	});

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = props.estate.name;
	}

	function validateForm () {
		return (!(form.name == ""));
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	async function onFormSubmit () {
		if (formValidated.value) {
			props.estate.name = form.name;
			props.estate.updated_at = new Date().toISOString();
			await estateStore.update(props.estate);
			onFormClear();
		}
	}

</script>
