<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4>Update User</h4>
				</div>
				<div class="card-body">
					<form @submit.prevent="update">
						<div class="row">
							<div class="col-12 mb-2">
								<div class="form-group">
									<label>First Name</label>
									<input v-model="thisuser.first_name" type="text" class="form-control">
									<label>Last Name</label>
									<input v-model="thisuser.last_name" type="text" class="form-control">
									<label>Email</label>
									<input v-model="thisuser.email" type="text" class="form-control">
									{{ thisuser.type }}
								</div>
							</div>
							<div class="col-12">
								<button type="submit" class="btn btn-primary">
									Update
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from "@/plugins/axios";

	export default {
		name: "UserEdit",
		data () {
			return {
				thisuser: {
					first_name: "",
					last_name: "",
					email: "",
					id: "",
					type: "",
					_method: "patch"
				}
			};
		},
		mounted () {
			this.showUser();
		},
		methods: {
			async showUser () {
				console.log(this.$route.params.id);
				await axios.get(route("api.user.show", { id: this.$route.params.id })).then(response=>{
					console.log(response);
					const { first_name, last_name, email, id, type } = response.data.data;
					this.thisuser.first_name = first_name;
					this.thisuser.last_name = last_name;
					this.thisuser.email = email;
					this.thisuser.id = id;
					this.thisuser.type = type;
				}).catch(error=>{
					console.log(error);
				});
			},
			async update () {
				console.log(this.thisuser);
				await axios.patch(route("api.user.update", { id: this.$route.params.id }), this.thisuser).then(response=>{
					this.$router.push({ name: "userList" });
				}).catch(error=>{
					console.log(error);
				});
			}
		}
	};
</script>
