<template>
	<div :id="activeSurveyId" :class="props.class" class="sd-root-modern sd-root-modern--full-container">
		<div class="sd-container-modern">
			<div v-show="useTitle" class="sd-title sd-container-modern__title">
				<div class="sd-header__text">
					<h3 class="sd-title" aria-label="Assessment for the Woodland Walk">
						<div class="sv-vue-title-additional-div">
							<span class="sv-string-viewer">{{ survey.title }}</span>
						</div>
					</h3>
					<h5 class="sd-description">
						<span class="sv-string-viewer">
							{{ survey.description }}
							<br>
							Asterisks (*) denote required questions.
						</span>
					</h5>
					<span class="sd-description">
						<span
							v-if="valid == 2 ? surveyValidated : (valid == 1 ? true : false)"
							style="color: rgb(var(--bs-success-rgb));"><i class="bi-check-square me-2" />Survey
							complete</span>
						<span v-else style="color: rgb( var(--bs-danger-rgb));"><i class="bi-x-square me-2" />Survey
							incomplete</span>
					</span>
					<!-- <span class="badge rounded-pill mx-2" :class="'badge-' + (survey.validate()?3:1)" v-text="' '"/> -->
				</div>
				<div class="sd-hidden" />
			</div>

			<div
				class="navigation-block"
				style="background-color:var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));margin-top:2px;">
				<div class="sd-container-modern">
					<div class="sv-components-row">
						<div :id="activeSurveyId + '_sp_141'" class="sd-body sd-body--responsive pt-2">
							<div class="sv-components-column">
								<div
									class="sd-action-bar sd-footer sd-clearfix d-flex justify-content-center"
									style="padding-bottom:0!important;padding-top:0!important;">
									<div id="sv-nav-prev" class="sv-action pe-2" height="100%" @click="survey.prevPage()">
										<button
											type="button" class="sd-btn sd-navigation__prev-btn sd-small-btn"
											:disabled="survey.isFirstPage">
											<div class="sv-action__content justify-content-center">
												<div class="small">
													<i class="bi bi-arrow-left" />
													<br>
													Go
													<br>
													Back
												</div>
											</div>
										</button>
									</div>
									<div id="sv-nav-preview" class="sv-action px-2" @click="survey.showPreview()">
										<button
											type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
											:disabled="survey.currentPage=='all'">
											<div class="sv-action__content justify-content-center">
												<div class="small">
													<i class="bi bi-clipboard-check" />
													<br>
													Save &<br>
													Summary
												</div>
											</div>
										</button>
									</div>

									<div id="sv-nav-next" class="sv-action ps-2" @click="survey.nextPage()">
										<button
											type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
											:disabled="survey.isLastPage">
											<div class="sv-action__content justify-content-center">
												<div class="small">
													<i class="bi bi-arrow-right" />
													<br>
													Save &
													<br>
													Next
												</div>
											</div>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<small
		v-show="!useTitle" class="p-2"
		style="color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));">Survey is saved
		automatically a question is answered.</small>
</template>

<script lang="ts" setup>
	/** WCA Root Component */
	import { ref, onMounted, computed } from "vue";
	import "survey-core/defaultV2.min.css";
	StylesManager.applyTheme("defaultV2");
	import { StylesManager } from "survey-core";
	import { Model } from "survey-core";
	import { useSurveyStore } from "@/stores/survey";
	import { useAuthStore } from "@/stores/auth";
	import { useAlertStore } from "@/stores/alert";
	import UserService from "@/services/UserService";
	import AuthService from "@/services/AuthService";
	import { usePwaStore } from "@/stores/pwa";
	import { getResponseError } from "@/helpers/api";
	import axios from "@/plugins/axios";

	import * as SurveyCore from "survey-core";
	import noUiSlider from "nouislider";
	// @ts-ignore
	import { nouislider } from "surveyjs-widgets";
	import "nouislider/dist/nouislider.css";

	const props = defineProps<{
		survey: Model;
		activeSurveyId: string;
		valid: number | null;
		useTitle: boolean;
		class: string | null;
	}>();

	import { plotSurveyJson } from "@/Components/plotSurveyJson";
	const surveyValidated = computed (() => {
		if (!props.survey["isShowingPreview"]) return props.survey.validate();

		const psurvey = new Model(plotSurveyJson);
		if (props.survey.survey_data) {
			const data = JSON.parse(props.survey.survey_data);
			psurvey.data = data;
		}
		return psurvey.validate();
	});

	const emit = defineEmits(["reinitialise-survey"]);
	// function restart() {
	// 	props.survey.cancelPreview(props.survey.pages[0]);
	// }
</script>

<style>
/* .accordion-button:hover {
  background-color: rgba(var(--wca-menu-background),0.6)!important;
}
.accordion-button {
  background-color: rgba(var(--wca-background),0.1)!important;
  background: rgba(var(--wca-background),0.1)!important
}
 .accordion-button:not(.collapsed) {
    background-color: rgba(var(--wca-menu-background),0.8)!important;
}
.accordion-button:not(.collapsed):hover {
	background-color: rgba(var(--wca-menu-background),1)!important;
} */

.noUi-origin{
	width:10%;
}
.sd-small-btn {
	/* max-width: 100px; */
	padding: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3*(var(--sjs-base-unit, var(--base-unit, 8px))));
}
</style>
