<template>
	<Modal
		:id="elementId"
		:has-form="true"
		:title="trans('users.labels.update_client')"
		@submit="onFormSubmit" @clear="onFormClear" @close="onFormClear">
		<UiForm
			:type="'update'"
			:data-bs-dismiss="formValidated"
			@submit="onFormSubmit()"
			@clear="onFormClear"
			@close="onFormClear">
			<TextInput v-model="form.name" type="text" :required="true" :label="'Client ' + trans('users.labels.name')" name="name" class="mb-2"/>
		</UiForm>
	</Modal>
</template>

<script lang="ts" setup>
	// import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed, watch } from "vue";
	// import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	// import { useAlertStore } from "@/stores";
	import { usePwaStore } from "@/stores/pwa";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { useClientStore } from "@/stores/client";

	// const authService = new AuthService();
	// const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const clientStore = useClientStore();

	const form = reactive({
		name: ""
	});

	const props = defineProps<{
		elementId:string;
		client:null|any;
	}>();

	watch(() => props.client, (newVal) => {
		onFormClear();
	});

	function validateForm () {
		return (!(form.name == ""));
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = props.client.name;
	}
	async function onFormSubmit () {
		if (formValidated.value) {
			props.client.name = form.name;
			props.client.updated_at = new Date().toISOString().replace("T", " ");
			await clientStore.update(props.client);
			onFormClear();
		}
	}

</script>
