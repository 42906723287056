import { default as PageLogin } from "@/views/pages/auth/login/Main.vue";
import { default as PageVerify } from "@/views/pages/auth/verify/Main.vue";
import { default as PageRegister } from "@/views/pages/auth/register/Main.vue";
import { default as PageResetPassword } from "@/views/pages/auth/reset-password/Main.vue";
import { default as PageForgotPassword } from "@/views/pages/auth/forgot-password/Main.vue";
import { default as PageNotFound } from "@/views/pages/shared/404/Main.vue";

import { default as PageDashboard } from "@/views/pages/private/dashboard/Main.vue";
import { default as PageProfile } from "@/views/pages/private/profile/Main.vue";

import { default as PageUsers } from "@/views/pages/private/users/Index.vue";
import { default as PageUsersCreate } from "@/views/pages/private/users/Create.vue";
import { default as PageUsersEdit } from "@/views/pages/private/users/Edit.vue";

import { default as OpenSurvey } from "@/views/pages/private/dashboard/surveys/Main.vue";
import { default as UserProjects } from "@/views/pages/private/dashboard/UserProjects.vue";

import { default as WCAHome } from "@/views/pages/private/info/home.vue";
import { default as Help } from "@/views/pages/private/info/help.vue";
import { default as TermsConditions } from "@/views/pages/private/info/termsConditions.vue";
import { default as About } from "@/views/pages/private/info/about.vue";

import { default as AllSurveys } from "@/views/pages/private/dashboard/surveys/AllSurveys.vue";
import { default as SurveyInfo } from "@/views/pages/private/dashboard/surveys/SurveyInfo.vue";

import { default as AllSites } from "@/views/pages/private/dashboard/sites/AllSites.vue";
import { default as SiteInfo } from "@/views/pages/private/dashboard/sites/SiteInfo.vue";

import { default as ClientInfo } from "@/views/pages/private/dashboard/clients/ClientInfo.vue";

import { default as EstateInfo } from "@/views/pages/private/dashboard/estates/EstateInfo.vue";

import { default as UserEdit } from "@/Components/User/Edit.vue";
import { default as UserAdd } from "@/Components/User/Add.vue";
import { default as UserList } from "@/Components/User/List.vue";

import abilities from "@/stub/abilities";

const routes = [
	{
		name: "home",
		path: "/",
		meta: { requiresAuth: false },
		component: PageLogin
	},
	{
		name: "panel",
		path: "/panel",
		children: [
			{
				name: "dashboard",
				path: "dashboard",
				meta: { requiresAuth: true },
				component: PageDashboard
			},
			{
				name: "profile",
				path: "profile",
				// meta: { requiresAuth: true, isOwner: true },
				meta: { requiresAuth: true },
				component: PageProfile
			},
			{
				path: "users",
				children: [
					{
						name: "users.list",
						path: "list",
						meta: { requiresAuth: true, requiresAbility: abilities.LIST_USER },
						component: PageUsers
					},
					{
						name: "users.create",
						path: "create",
						meta: { requiresAuth: true, requiresAbility: abilities.CREATE_USER },
						component: PageUsersCreate
					},
					{
						name: "users.edit",
						path: ":id/edit",
						meta: { requiresAuth: true, requiresAbility: abilities.EDIT_USER },
						component: PageUsersEdit
					}
				]
			},
			{
				path: "/editsurvey/:surveyId/:siteId/:estateId/:clientId/:pill?/:item?",
				name: "editSurvey",
				meta: { requiresAuth: true },
				props: true,
				component: OpenSurvey
			},
			{
				// uses the '/panel/' base as no preceding slash
				path: "projects",
				name: "projects",
				meta: { requiresAuth: true },
				component: UserProjects
			}
		]
	},
	{
		path: "/login",
		name: "login",
		meta: { requiresAuth: false },
		component: PageLogin
	},
	{
		path: "/login/verified",
		name: "verify",
		meta: { requiresAuth: false },
		component: PageVerify,
		props: true
	},
	{
		path: "/register",
		name: "register",
		meta: { requiresAuth: false },
		component: PageRegister
	},
	{
		path: "/reset-password",
		name: "resetPassword",
		meta: { requiresAuth: false },
		component: PageResetPassword
	},
	{
		path: "/forgot-password",
		name: "forgotPassword",
		meta: { requiresAuth: false },
		component: PageForgotPassword
	},
	{
		path: "/wcahome",
		name: "wcahome",
		meta: { requiresAuth: false },
		component: WCAHome
	},
	{
		path: "/help",
		name: "help",
		meta: { requiresAuth: false },
		component: Help
	},
	{
		path: "/terms",
		name: "terms",
		meta: { requiresAuth: false },
		component: TermsConditions
	},
	{
		path: "/about",
		name: "about",
		meta: { requiresAuth: false },
		component: About
	},
	{
		name: "allSurveys",
		path: "/allsurveys/:completed",
		component: AllSurveys,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "surveyInfo",
		path: "/survey/:surveyId/:siteId/:estateId/:clientId",
		component: SurveyInfo,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "allSites",
		path: "/sites",
		component: AllSites,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "siteInfo",
		path: "/site/:siteId/:estateId/:clientId",
		component: SiteInfo,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "estateInfo",
		path: "/estate/:estateId/:clientId",
		component: EstateInfo,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "clientInfo",
		path: "/client/:clientId",
		component: ClientInfo,
		props: true,
		meta: { requiresAuth: true }
	},
	{
		name: "userList",
		path: "/user",
		component: UserList,
		meta: { requiresAuth: true }
	},
	{
		name: "userEdit",
		path: "/user/:id/edit",
		component: UserEdit,
		meta: { requiresAuth: true }
	},
	{
		name: "userAdd",
		path: "/user/add",
		component: UserAdd,
		meta: { requiresAuth: true }
	},
	{
		path: "/:catchAll(.*)",
		name: "notFound",
		meta: { requiresAuth: false },
		component: PageNotFound
	}
];

export default routes;
