/* eslint-disable no-mixed-spaces-and-tabs */
export const NVCSurveyJson =
{
	"title": "NVC Flowchart",
	"description": "A number of ancient woodland indicator plant species lists have been published. These may be regionally specific within the UK. The presence of several indicator species can help to identify an ancient woodland site. The folloing species are the most frequently used indicators: Wood Anemone (Anemone nemorosa), Ramsons (Allium ursinum), Wood Spurge (Euphorbia amygdaloiedes), Wood Sorrel (Oxalis acetosella), Wild Service (Sorbus torminalis), Small-leaved Lime (Tilia cordata) and Guelder Rose (Viburnum opulus).",
	"logoPosition": "right",
	"pages": [
	 {
	  "name": "page1",
	  "elements": [
	   {
					"type": "panel",
					"name": "panel20",
					"elements": [
		 {
		  "type": "boolean",
		  "name": "question1",
		  "title": "Are Alder and Willow prominent in the canopy?"
		 },
		 {
		  "type": "panel",
		  "name": "question54",
		  "elements": [
								{
									"type": "html",
									"name": "alder_head",
									"html": "<b>Alder</b>"
								},
								{
									"type": "html",
									"name": "alder_ims",
									"html":
										"<div class=\"masonry-wall d-flex\" style=\"gap: 5px;\">" +
										"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Common Alder leaf</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/alder/17_common_alder_leaf.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Italian Alder leaf</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/alder/italian_alder.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"	</div>" +
										"	<div class=\"masonry-column d-flex\" data-index=\"1\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 16px; height: max-content; min-width: 0px;\">" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Alder_buckthorn_leaf_shutterstock_156618092</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/alder/Alder_buckthorn_leaf_shutterstock_156618092.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>34_Alder_fruit_detail</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/alder/34_Alder_fruit_detail.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"	</div>" +
										"</div>"
								},
								{
									"type": "html",
									"name": "willow_head",
									"html": "<b>Willow</b>"
								},
								{
									"type": "html",
									"name": "willow_ims",
									"html":	"<div class=\"masonry-wall d-flex\" style=\" gap: 5px;\">" +
									"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Almond Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/almond willow.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Crack Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/crack willow.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Eared Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/eared willow.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Osier leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/osier.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"	</div>" +
									"	<div class=\"masonry-column d-flex\" data-index=\"1\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 16px; height: max-content; min-width: 0px;\">" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Bay Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/bay willow.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Goat Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/17 Goat willow leaf.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Grey Willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/rusty willow top.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>White willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/white willow top.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"		<div class=\"masonry-item\">" +
									"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"				<div class=\"card-header\">" +
									"					<span class=\"card-title\">" +
									"						<small>Purple willow leaf</small>" +
									"					</span>" +
									"				</div>" +
									"				<img class=\"card-img-top\" src=\"/nvc/willow/purple willow.jpg\" alt=\"Card image cap\">" +
									"			</div>" +
									"		</div>" +
									"	</div>" +
									"</div>"
								}
		  ],
		  "title": "Image guide to Alder and Willow",
		  "state": "collapsed"
		 }
					]
	   },
	   {
					"type": "panel",
					"name": "panel23",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question2",
		  "title": "Which species are more prominent?",
		  "choices": [
		   {
									"value": "Item 1",
									"text": "Alder and/or Crack Willow - or sometimes Almond-leaved Willow, Common Osier and/or hybrids"
		   },
		   {
									"value": "Item 2",
									"text": "Grey Sallow and/or Common White Birch - or sometimes Bay Willow, but other trees and shrubs are rare"
		   }
		  ]
		 },
		 {
		  "type": "panel",
		  "name": "question55",
		  "elements": [
								{
									"type": "html",
									"name": "alder_head2",
									"html": "<b>Alder</b>"
								},
								{
									"type": "html",
									"name": "alder_ims2",
									"html":
					"<div class=\"masonry-wall pb-2 d-flex\" style=\" gap: 5px;\">" +
					"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
					"<div class=\"masonry-item\">" +
					"		<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"			<div class=\"card-header\">" +
					"				<span class=\"card-title\">" +
					"					<small>Common Alder branch</small>" +
					"				</span>" +
					"			</div>" +
					"			<img class=\"card-img-top\" src=\"/nvc/alder/88191.jpg\" alt=\"Card image cap\">" +
					"		</div>" +
					"	</div>" +
					"	<div class=\"masonry-item\">" +
					"		<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"			<div class=\"card-header\">" +
					"				<span class=\"card-title\">" +
					"					<small>Alder male and female flowers</small>" +
					"				</span>" +
					"			</div>" +
					"			<img class=\"card-img-top\" src=\"/nvc/alder/alder-male-and-female-flowers-wtml-1044266-nature-photographers-ltd.jpg\" alt=\"Card image cap\">" +
					"		</div>" +
					"	</div></div></div>" +
					"<div class=\"masonry-wall d-flex\" style=\"gap: 5px;\">" +
					"	<div class=\"masonry-column\" data-index=\"0\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
					"		<div class=\"masonry-item\">" +
					"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"				<div class=\"card-header\">" +
					"					<span class=\"card-title\">" +
					"						<small>Common Alder leaf</small>" +
					"					</span>" +
					"				</div>" +
					"				<img class=\"card-img-top\" src=\"/nvc/alder/17_common_alder_leaf.jpg\" alt=\"Card image cap\">" +
					"			</div>" +
					"		</div>" +
					"		<div class=\"masonry-item\">" +
					"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"				<div class=\"card-header\">" +
					"					<span class=\"card-title\">" +
					"						<small>Italian Alder leaf</small>" +
					"					</span>" +
					"				</div>" +
					"				<img class=\"card-img-top\" src=\"/nvc/alder/italian_alder.jpg\" alt=\"Card image cap\">" +
					"			</div>" +
					"		</div>" +
					"	</div>" +
					"	<div class=\"masonry-column d-flex\" data-index=\"1\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 16px; height: max-content; min-width: 0px;\">" +
					"		<div class=\"masonry-item\">" +
					"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"				<div class=\"card-header\">" +
					"					<span class=\"card-title\">" +
					"						<small>Alder_buckthorn_leaf_shutterstock_156618092</small>" +
					"					</span>" +
					"				</div>" +
					"				<img class=\"card-img-top\" src=\"/nvc/alder/Alder_buckthorn_leaf_shutterstock_156618092.jpg\" alt=\"Card image cap\">" +
					"			</div>" +
					"		</div>" +
					"		<div class=\"masonry-item\">" +
					"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"				<div class=\"card-header\">" +
					"					<span class=\"card-title\">" +
					"						<small>34_Alder_fruit_detail</small>" +
					"					</span>" +
					"				</div>" +
					"				<img class=\"card-img-top\" src=\"/nvc/alder/34_Alder_fruit_detail.jpg\" alt=\"Card image cap\">" +
					"			</div>" +
					"		</div>" +
					"	</div>" +
					"</div>"
								},
								{
									"type": "html",
									"name": "willow_head2",
									"html": "<b>Willow</b>"
								},
								{
									"type": "html",
									"name": "willow_ims2",
									"html":
				"<div class=\"masonry-wall d-flex\" style=\" gap: 5px;\">" +
				"	<div class=\"masonry-column\" data-index=\"0\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Almond Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/almond willow.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Crack Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/crack willow.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Eared Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/eared willow.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Osier leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/osier.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"	</div>" +
				"	<div class=\"masonry-column d-flex\" data-index=\"1\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 16px; height: max-content; min-width: 0px;\">" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Bay Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/bay willow.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Goat Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/17 Goat willow leaf.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Grey Willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/rusty willow top.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>White willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/white willow top.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Purple willow leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/willow/purple willow.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"	</div>" +
				"</div>"
								},
								{
									"type": "html",
									"name": "birch_head",
									"html": "<b>Birch</b>"
								},
								{
									"type": "html",
									"name": "birch_ims",
									"html":
				"<div class=\"masonry-wall d-flex\" style=\"gap: 5px;\">" +
				"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\" flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Silver Birch leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/birch/11 Birch leaf.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Downy Birch leaf</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/birch/26 downy birch leaf.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"	</div><div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
				"		<div class=\"masonry-item\">" +
				"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
				"				<div class=\"card-header\">" +
				"					<span class=\"card-title\">" +
				"						<small>Birch twig</small>" +
				"					</span>" +
				"				</div>" +
				"				<img class=\"card-img-top\" src=\"/nvc/birch/Birch.jpg\" alt=\"Card image cap\">" +
				"			</div>" +
				"		</div>" +
				"	</div>" +
				"</div>"
								}
		  ],
		  "title": "Image guide to Alder, Willow, and Birch",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel21",
					"elements": [
		 {
		  "type": "boolean",
		  "name": "question3",
		  "visibleIf": "{question2} = 'Item 1'",
		  "title": "Are Great Tussocks rare or absent?"
		 },
		 {
		  "type": "panel",
		  "name": "great_tussocks_images",
		  "elements": [
								{
									"type": "html",
									"name": "tuss_head",
									"html": "<b>Greater Tussock-sedge</b>"
								},
								{
									"type": "html",
									"name": "tuss_ims",
									"html":
										"<div class=\"masonry-wall\" style=\"display: flex; gap: 5px;\">" +
										"	<div class=\"masonry-column\" data-index=\"0\" style=\"display: flex; flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Greater Tusssock-sedge</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/great_tussock_sedge.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"</div>"
								}
		  ],
		  "visibleIf": "{question2} = 'Item 1'",
		  "title": "Image guide to Great Tussocks",
		  "state": "collapsed"
		 }
					]
	   },
	   {
					"type": "panel",
					"name": "panel22",
					"elements": [
		 {
		  "type": "boolean",
		  "name": "question6",
		  "title": "Is nettle absent?",
		  "description": "or, if present, then with opposite-leaved Golden Saxifrage, Yellow Pimpernell, or Ladyfern"
		 },
		 {
		  "type": "panel",
		  "name": "nettle_images",
		  "elements": [
								{
									"type": "html",
									"name": "nettle_head",
									"html": "<b>Nettle</b>"
								},
								{
									"type": "html",
									"name": "tuss_ims",
									"html":
					"<div class=\"masonry-wall d-flex\" style=\"gap: 5px;\">" +
					"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
					"		<div class=\"masonry-item\">" +
					"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"				<div class=\"card-header\">" +
					"					<span class=\"card-title\">" +
					"						<small>Common Nettle</small>" +
					"					</span>" +
					"				</div>" +
					"				<img class=\"card-img-top\" src=\"/nvc/nettle.jpg\" alt=\"Card image cap\">" +
					"			</div>" +
					"		</div>" +
					"</div>"
								}
		  ],
		  "visibleIf": "{question3} = true",
		  "title": "Image guide to Nettle",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question3} = true"
	   },
	   {
					"type": "panel",
					"name": "panel2",
					"elements": [
		 {
		  "type": "image",
		  "name": "question7",
		  "imageLink": "/nvc/w6.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question8",
		  "html": "<h4>Common Alder Nettle Woodland</h4>\n\n<table>\n<tr><td><b>NVC number</b></td><td>W6</td></tr>\n<tr><td><b>NVC name</b></td><td> Alder woodland with stinging nettle</td></tr><tr><td><b>Description</b></td><td><b>Nettle abundant</b> plus two of: common cleavers, rough meadow-grass, bramble, or broad buckler fern. Markedly Eutrophic on fen peat with some nutrient accumulation or rich alluvium. May contain other willow.</td></td></table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question6} = false"
	   },
	   {
					"type": "panel",
					"name": "panel1",
					"elements": [
		 {
		  "type": "image",
		  "name": "question4",
		  "imageLink": "/nvc/w7.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question5",
		  "html": "<h4> Alder Carr Woodland – W5</h4>\n<h5>Alnus glutinosa – Carex paniculata woodland\n</h5>"
		 }
					],
					"visibleIf": "{question3} = false and {question2} = 'Item 1' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel24",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question9",
		  "title": "Which statement best describes the woodland?",
		  "choices": [
		   {
									"value": "Item 1",
									"text": "Ground flora includes meadowsweet, yellow pimpernel, lady fern, rough meadow-grass, creeping soft-grass, creeping buttercup and/or opposite-leaved golden saxifrage."
		   },
		   {
									"value": "Item 2",
									"text": "Variable ground flora but common reed constant with meadowsweet, nettle, hemp agrimony."
		   }
		  ]
		 }
						//  {
						//   "type": "panel",
						//   "name": "question59",
						//   "elements": [
						//    {
						// 							"type": "image",
						// 							"name": "question60",
						// 							"imageLink": "https://api.surveyjs.io/private/Surveys/files?name=0b90f768-96ed-4ade-aa7b-d29a812a1658",
						// 							"imageFit": "cover",
						// 							"imageHeight": "auto",
						// 							"imageWidth": "100%"
						//    },
						//    {
						// 							"type": "image",
						// 							"name": "question61",
						// 							"imageLink": "https://api.surveyjs.io/private/Surveys/files?name=0b90f768-96ed-4ade-aa7b-d29a812a1658",
						// 							"imageFit": "cover",
						// 							"imageHeight": "auto",
						// 							"imageWidth": "100%"
						//    }
						//   ],
						//   "title": "Identifying images",
						//   "state": "collapsed"
						//  }
					],
					"visibleIf": "{question6} = true and {question3} = true and {question2} = 'Item 1' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel3",
					"elements": [
		 {
		  "type": "image",
		  "name": "question10",
		  "imageLink": "/nvc/w7.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question11",
		  "html": "<h4>COMMON ALDER - ASH - YELLOW PIMPERNEL WOODLAND</h4><table>\n<tr><td><b>NVC number</b></td><td>W7</td></tr>\n<tr><td><b>NVC name</b></td><td> Alder-ash woodland with yellow pimpernel</td></tr>\n<tr><td><b>Soil type</b></td><td> Wet gleys and brown earths</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question9} = 'Item 1' and {question6} = true and {question3} = true and {question2} = 'Item 1' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel4",
					"elements": [
		 {
		  "type": "image",
		  "name": "question12",
		  "imageLink": "/nvc/w1.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question13",
		  "html": "<h4>GREY SALLOW – WHITE BIRCH – COMMON REED WOODLAND</h4>\n<strong>Alder-Meadowsweet sub-community</strong><table>\n<tr><td><b>NVC number</b></td><td>W2a</td></tr>\n<tr><td><b>NVC name</b></td><td>Grey Sallow, White Birch and Common Reed Woodland</td></tr>\n<tr><td><b>Soil type</b></td><td>Moderately base and nutrient rich. Like W5, but drier. On fen peat in topogenous mires.</td></tr>\n<tr><td><b>Details</b></td><td>Variable ground flora but common reed constant with meadowsweet, nettle, hemp agrimony.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question9} = 'Item 2' and {question6} = true and {question3} = true and {question2} = 'Item 1' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel25",
					"elements": [
		 {
		  "type": "boolean",
		  "name": "question14",
		  "title": "Is Bay Willow present?",
		  "description": "and the following ground flora absent: meadowsweet, yellow pimpernel, lady fern, rough meadow-grass, creeping soft-grass, creeping buttercup and/or opposite-leaved golden saxifrage, meadowsweet, nettle, hemp agrimony, bramble species, thistle, lesser pond sedge, broad buckler fern, water mint, common valerian."
		 },
		 {
		  "type": "panel",
		  "name": "panel26",
		  "elements": [
								{
									"type": "html",
									"name": "willow_ims",
									"html": "<div class=\"masonry-wall pb-2 d-flex\" style=\"gap: 5px;\">" +
								"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
								"		<div class=\"masonry-item\">" +
								"			<div class=\"card flex items-center justify-center\" style=\"gap:0px;\">" +
								"				<div class=\"card-header\">" +
								"					<span class=\"card-title\">" +
								"						<small>Bay Willow leaf</small>" +
								"					</span>" +
								"				</div>" +
								"				<img class=\"card-img-top\" src=\"/nvc/willow/bay willow.jpg\" alt=\"Card image cap\">" +
								"			</div>" +
								"		</div>" +
								"	</div>" +
								"</div>"
								}
		  ],
		  "title": "Image guide to Bay Willow",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel27",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question15",
		  "title": "Which species is more abundant?",
		  "choices": [
		   {
									"value": "willow",
									"text": "Grey Willow"
		   },
		   {
									"value": "birch",
									"text": "Downy Birch"
		   },
		   {
									"value": "both",
									"text": "Both are equally abundant"
		   }
		  ]
		 },
		 {
		  "type": "panel",
		  "name": "question63",
		  "elements": [
								{
									"type": "html",
									"name": "gw_head",
									"html": "<b>Grey Willow</b>"
								},
								{
									"type": "html",
									"name": "gw_ims",
									"html": "<div class=\"masonry-wall pb-2 d-flex\" style=\"gap: 5px;\">" +
								"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
								"		<div class=\"masonry-item\">" +
								"			<div class=\"card flex items-center justify-center\" style=\"gap:0px;\">" +
								"				<div class=\"card-header\">" +
								"					<span class=\"card-title\">" +
								"						<small>Grey Willow leaf</small>" +
								"					</span>" +
								"				</div>" +
								"				<img class=\"card-img-top\" src=\"/nvc/willow/rusty willow top.jpg\" alt=\"Card image cap\">" +
								"			</div>" +
								"		</div>" +
								"	</div>" +
								"</div>"
								},
								{
									"type": "html",
									"name": "db_head",
									"html": "<b>Downy Birch</b>"
								},
								{
									"type": "html",
									"name": "db_ims",
									"html": "<div class=\"masonry-wall pb-2\" style=\"display: flex; gap: 5px;\">" +
								"	<div class=\"masonry-column\" data-index=\"0\" style=\"display: flex; flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
								"		<div class=\"masonry-item\">" +
								"			<div class=\"card flex items-center justify-center\" style=\"gap:0px;\">" +
								"				<div class=\"card-header\">" +
								"					<span class=\"card-title\">" +
								"						<small>Downy Birch leaf</small>" +
								"					</span>" +
								"				</div>" +
								"				<img class=\"card-img-top\" src=\"/nvc/birch/26 downy birch leaf.jpg\" alt=\"Card image cap\">" +
								"			</div>" +
								"		</div>" +
								"	</div>" +
								"</div>"
								}
		  ],
		  "title": "Image guide to Grey Willow and Downy Birch",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question14} = true and {question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel8",
					"elements": [
		 {
		  "type": "image",
		  "name": "question22",
		  "imageLink": "/nvc/w1.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question23",
		  "html": "<h4>BAY WILLOW - BOTTLE-SEDGE WOODLAND</h4>\n<table>\n<tr><td><b>NVC number</b></td><td>W3</td></tr>\n<tr><td><b>NVC name</b></td><td>Bay willow and bottle-sedge woodland</td></tr>\n<tr><td><b>Soil type</b></td><td>Wet, poor-fen counterpart of W5. Northern sub-montaine. On less acidic oligotrophic basin mires.</td></tr>\n<tr><td><b>Description</b></td><td>Grey willow, bay willow mixtures, with some downy birch, over bottle-sedge, water-horsetail, cuckooflower, marsh bedstraw, marsh-marigold, angelica, marsh valerian, water avens, marsh hawk's-beard. Extensive Bryophyte mat.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question14} = false and {question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel28",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question24",
		  "title": "How common is Beech in the canopy?",
		  "choices": [
		   {
									"value": "common",
									"text": "Beech is constant and dominant in the canopy (common)."
		   },
		   {
									"value": "uncommon",
									"text": "Beech is rare or absent"
		   }
		  ]
		 },
		 {
		  "type": "panel",
		  "name": "panel29",
		  "elements": [
								{
									"type": "html",
									"name": "beech_head",
									"html": "<b>Beech</b>"
								},
								{
									"type": "html",
									"name": "beech_ims",
									"html": "<div class=\"masonry-wall pb-2 d-flex\" style=\"gap: 5px;\">" +
										"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
										"	<div class=\"masonry-item\">" +
										"		<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"			<div class=\"card-header\">" +
										"				<span class=\"card-title\">" +
										"					<small>Beech branch</small>" +
										"				</span>" +
										"			</div>" +
										"			<img class=\"card-img-top\" src=\"/nvc/beech/Rodale-beech-botanical-illustration-by-Lizzie-Harper.jpg\" alt=\"Card image cap\">" +
										"		</div>" +
										"	</div>" +
										"	</div></div>" +
										"<div class=\"masonry-wall d-flex\" style=\"gap: 5px;\">" +
										"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Beech leaf</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/beech/07 Beech leaf.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Beech twig</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/beech/Beech.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"	</div>" +
										"	<div class=\"masonry-column d-flex\" data-index=\"1\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 16px; height: max-content; min-width: 0px;\">" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Rauli Beech leaf</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/beech/Rauli.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"		<div class=\"masonry-item\">" +
										"			<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
										"				<div class=\"card-header\">" +
										"					<span class=\"card-title\">" +
										"						<small>Beech fruit</small>" +
										"					</span>" +
										"				</div>" +
										"				<img class=\"card-img-top\" src=\"/nvc/beech/beech fruit cutout.jpg\" alt=\"Card image cap\">" +
										"			</div>" +
										"		</div>" +
										"	</div>" +
										"</div>"
								}
		  ],
		  "title": "Image guide to Beech",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question1} = false"
	   },
	   {
					"type": "radiogroup",
					"name": "question25",
					"visibleIf": "{question24} = 'common' and {question1} = false",
					"title": "Describe the acidity of the soil:",
					"choices": [
		 {
		  "value": "Item 1",
		  "text": "Acidic"
		 },
		 {
		  "value": "Item 2",
		  "text": "Base rich"
		 },
		 {
		  "value": "Item 3",
		  "text": "Neither"
		 }
					]
	   },
	   {
					"type": "panel",
					"name": "panel30",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question32",
		  "title": "How common is Yew in the canopy?",
		  "choices": [
		   {
									"value": "common",
									"text": "Yew is abundant, often dense, and there is poor ground flora"
		   },
		   {
									"value": "uncommon",
									"text": "Yew is rare or absent"
		   }
		  ]
		 },
		 {
		  "type": "panel",
		  "name": "panel31",
		  "elements": [{
								"type": "html",
								"name": "yew_head",
								"html": "<b>Yew</b>"
							},
							{
								"type": "html",
								"name": "yew_ims",
								"html": "<div class=\"masonry-wall pb-2 d-flex\" style=\"gap: 5px;\">" +
									"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
									"	<div class=\"masonry-item\">" +
									"		<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
									"			<div class=\"card-header\">" +
									"				<span class=\"card-title\">" +
									"					<small>Yew branch</small>" +
									"				</span>" +
									"			</div>" +
									"			<img class=\"card-img-top\" src=\"/nvc/yew/yew.jpg\" alt=\"Card image cap\">" +
									"		</div>" +
									"	</div>" +
									"	</div></div>"
							}
		  ],
		  "title": "Image guide to Yew",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question1} = false and {question24} = 'uncommon'"
	   },
	   {
					"type": "panel",
					"name": "panel32",
					"elements": [
		 {
		  "type": "radiogroup",
		  "name": "question35",
		  "title": "How common is Scot's Pine?",
		  "choices": [
		   {
									"value": "common",
									"text": "Scot's Pine is dominant"
		   },
		   {
									"value": "uncommon",
									"text": "The woodland is mixed deciduous, and oak/birch woodland."
		   }
		  ]
		 },
		 {
		  "type": "panel",
		  "name": "panel33",
		  "elements": [
								{
									"type": "html",
									"name": "scotspine_head",
									"html": "<b>Scot's Pine</b>"
								},
								{
									"type": "html",
									"name": "scotspine_ims",
									"html": "<div class=\"masonry-wall pb-2 d-flex\" style=\" gap: 5px;\">" +
					"	<div class=\"masonry-column d-flex\" data-index=\"0\" style=\"flex-basis: 100px; flex-direction: column; flex-grow: 1; gap: 5px; height: max-content; min-width: 0px;\">" +
					"	<div class=\"masonry-item\">" +
					"		<div class=\"card d-flex items-center justify-center\" style=\"gap:0px;\">" +
					"			<div class=\"card-header\">" +
					"				<span class=\"card-title\">" +
					"					<small>Scot's Pine leaf</small>" +
					"				</span>" +
					"			</div>" +
					"			<img class=\"card-img-top\" src=\"/nvc/pine/Scots pine leaf.jpg\" alt=\"Card image cap\">" +
					"		</div>" +
					"	</div>" +
					"	</div></div>"
								}
		  ],
		  "title": "Image guide to Scot's Pine",
		  "state": "collapsed"
		 }
					],
					"visibleIf": "{question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon'"
	   },
	   {
					"type": "radiogroup",
					"name": "question38",
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon'",
					"title": "Which statement best describes the land?",
					"choices": [
		 {
		  "value": "lowland",
		  "text": "LOWLAND / SOUTH EAST: Warmer, longer summers (maple, dogwood, spindle, lime, hornbeam); Low rainfall (more pedunculate oak & silver birch); Soils richer in clay, less easily leached."
		 },
		 {
		  "value": "upland",
		  "text": "UPLAND / NORTH WEST (and other localised oceanic sites): Cooler, shorter summers (Bird cherry, stone bramble, globeflower, marsh hawk’s-beard); High rainfall (more sessile oak & downy birch, bryophyte & fern rich); More leaching – freely draining soils more common."
		 }
					]
	   },
	   {
					"type": "radiogroup",
					"name": "question39",
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and {question38} notempty",
					"title": "Describe the acidity of the soil:",
					"choices": [
		 {
		  "value": "Item 1",
		  "text": "Acidic"
		 },
		 {
		  "value": "Item 2",
		  "text": "Base rich"
		 },
		 {
		  "value": "Item 3",
		  "text": "Neither"
		 }
					]
	   },
	   {
					"type": "html",
					"name": "question29",
					"html": "<p class=\"py-2\">Based on your answers, the following NVC communities mayb be present:</p>"
					// "visibleIf": "(({question39} = '') and {question24} = 'common' and {question1} = false) or " +
					// 	"({question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = '') and {question38} = 'lowland') or " +
					// 	"({question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and {{question39} = '') and {question38} = 'upland') or " +
					// 	"(({question25} = '') and {question24} = 'common' and {question1} = false)" +
					// 	""
	   },
	   {
					"type": "panel",
					"name": "panel5",
					"elements": [
		 {
		  "type": "image",
		  "name": "question16",
		  "imageLink": "/nvc/w1.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question17",
		  "html": "<h4>GREY WILLOW – MARSH BEDSTRAW WOODLAND</h4>\n\n<table>\n<tr><td><b>NVC number</b></td><td>W1</td></tr>\n<tr><td><b>NVC name</b></td><td>Grey Willow and Marsh Bedstraw Woodland</td></tr>\n<tr><td><b>Soil type</b></td><td>Waterlogged mineral soils, only moderate base and nutrient status where alder invasion has been <strong>restricted</strong>.</td></tr>\n<tr><td><b>Details</b></td><td>Grey willow abundant. Downy birch occasional. Varied ground flora - water mint and marsh bedstraw frequent. Purple moor-grass rare.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question15} = 'willow' and {question14} = true and {question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel6",
					"elements": [
		 {
		  "type": "image",
		  "name": "question18",
		  "imageLink": "/nvc/w4.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question19",
		  "html": "<h4>DOWNY BIRCH - PURPLE MOORGRASS WOODLAND</h4>\n\n<table>\n<tr><td><b>NVC number</b></td><td>W4</td></tr>\n<tr><td><b>NVC name</b></td><td> Birch woodland with purple moor-grass</td></tr>\n<tr><td><b>Soil type</b></td><td>Wet peat. Calcifuge conditions around ombrogenous mires or raised-basin mires. Moist, acidic, peaty soils. Poor in rich-fen herbs.</td></tr>\n<tr><td><b>Description</b></td><td>Downy birch abundant, grey willow occasional, purple moor-grass abundant, sphagnum mosses abundant.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question15} = 'birch' and {question14} = true and {question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel7",
					"elements": [
		 {
		  "type": "image",
		  "name": "question20",
		  "imageLink": "/nvc/w1.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question21",
		  "html": "<h4>GREY WILLOW – DOWNY BIRCH – COMMON REED WOODLAND</h4>\n<strong>Sphagnum subcommunity</strong>\n<table>\n<tr><td><b>NVC number</b></td><td>W2b</td></tr>\n<tr><td><b>NVC name</b></td><td>Grey willow, downy birch, and common reed woodland</td></tr>\n<tr><td><b>Soil type</b></td><td>Little influence from calcareous or base-rich ground-water. Often on raised peat. fairly acidic and nutrient poor.</td></tr>\n<tr><td><b>Description</b></td><td>Grey willow and downy birch both frequent. Common reed constant. Prominent carpet of sphagnummosses. Ferns abundant.</td></tr>\n</table>" +
		  			"<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question15} = 'both' and {question14} = true and {question2} = 'Item 2' and {question1} = true"
	   },
	   {
					"type": "panel",
					"name": "panel9",
					"elements": [
		 {
		  "type": "image",
		  "name": "question26",
		  "imageLink": "/nvc/w15.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question27",
		  "html": "<h4>BEECH – WAVY HAIR-GRASS WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W15</td></tr>\n<tr><td><b>NVC name</b></td><td> Beech-oak woodland with wavy hair-grass </td></tr>\n<tr><td><b>Soil type</b></td><td>Podzols, acidic</td></tr>\n<tr><td><b>Description</b></td><td>Oak/birch and/or holly present. Species-poor ground flora: wavy hair-grass, bilberry, heather. Similar to W16, with the additional presence of beech.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "({question25} = '' or {question25} = 'Item 1') and {question24} = 'common' and {question1} = false"
	   },
	   {
					"type": "panel",
					"name": "panel10",
					"elements": [
		 {
		  "type": "image",
		  "name": "question28",
		  "imageLink": "/nvc/w14.jpg",
		  "imageFit": "cover",
		  "imageHeight": "auto",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question29",
		  "html": "<h4>BEECH – BRAMBLE WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W14</td></tr>\n<tr><td><b>NVC name</b></td><td> Beech-oak woodland with bramble </td></tr>\n<tr><td><b>Soil type</b></td><td>Base-poor brown earths</td></tr>\n<tr><td><b>Description</b></td><td>Holly abundant, other shrubs rare. Sparse ground flora: bramble and bracken and patchy wood millet, wood melick, tufted hair-grass/ Ivy, butchers-broom.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "({question25} = '' or {question25} = 'Item 3') and {question24} = 'common' and {question1} = false"
	   },
	   {
					"type": "panel",
					"name": "panel11",
					"elements": [
		 {
		  "type": "image",
		  "name": "question30",
		  "imageLink": "/nvc/beech_mercury.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question31",
		  "html": "<h4>BEECH – DOG’S MERCURY WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W12</td></tr>\n<tr><td><b>NVC name</b></td><td> Beach-ash woodland with dog's mercury </td></tr>\n<tr><td><b>Soil type</b></td><td>Rendzinas, base rich</td></tr>\n<tr><td><b>Description</b></td><td>Ash, sycamore, yew, whitebeam, hazel, hawthorn present. Holly rare. Fairly rich ground flora dominated by dog's mercury and/or ivy.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "({question25} = '' or {question25} = 'Item 2') and {question24} = 'common' and {question1} = false"
	   },
	   {
					"type": "panel",
					"name": "panel12",
					"elements": [
		 {
		  "type": "image",
		  "name": "question33",
		  "imageLink": "/nvc/w13.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question34",
		  "html": "<h4>YEW WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W13</td></tr>\n<tr><td><b>NVC name</b></td><td> Yew abundant </td></tr>\n<tr><td><b>Soil type</b></td><td>Dry redzinas, base rich</td></tr>\n<tr><td><b>Description</b></td><td>Similar to W12 but no beech. Yew often dense with poor ground flora.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question32} = 'common' and {question1} = false and {question24} = 'uncommon'"
	   },
	   {
					"type": "panel",
					"name": "panel13",
					"elements": [
		 {
		  "type": "image",
		  "name": "question36",
		  "imageLink": "/nvc/w18.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question37",
		  "html": "<h4>SCOT'S PINE – MOSS WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W18</td></tr>\n<tr><td><b>NVC name</b></td><td>Scot's Pine woodland with Heather</td></tr>\n<tr><td><b>Soil type</b></td><td>Highly acidic podzols </td></tr>\n<tr><td><b>Description</b></td><td></td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'common' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon'"
	   },
	   {
					"type": "panel",
					"name": "panel14",
					"elements": [
		 {
		  "type": "image",
		  "name": "question40",
		  "imageLink": "/nvc/w16.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question41",
		  "html": "<h4>OAK – BIRCH – WAVY HAIRGRASS WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W16</td></tr>\n<tr><td><b>NVC name</b></td><td> Lowland oak-birch woodland with bilberry </td></tr>\n<tr><td><b>Soil type</b></td><td> Podzolic and ironpan soils, acidic </td></tr>\n<tr><td><b>Description</b></td><td>Oak/birch, some rowan. Species poor ground flora: wavy hair-grass, bracken</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 1' or {question39} = '') and {question38} = 'lowland'"
	   },
	   {
					"type": "panel",
					"name": "panel15",
					"elements": [
		 {
		  "type": "image",
		  "name": "question42",
		  "imageLink": "/nvc/w10.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question43",
		  "html": "<h4>PEDUNCULATE OAK – BRACKEN – BRAMBLE WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W10</td></tr>\n<tr><td><b>NVC name</b></td><td> Lowland mixed broadleaved woodland with bluebell </td></tr>\n<tr><td><b>Soil type</b></td><td> Base-rich brown earths </td></tr>\n<tr><td><b>Description</b></td><td>Oak/birch sometimes with chestnut, lime, hornbeam. Beech, ash or sycamore occur only rarely. Hazel and hawthorn common. Bramble, bracken, honeysuckle, wood anemone or bluebell sometimes dominant.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 3' or {question39} = '') and {question38} = 'lowland'"
	   },
	   {
					"type": "panel",
					"name": "panel16",
					"elements": [
		 {
		  "type": "image",
		  "name": "question44",
		  "imageLink": "/nvc/w8.jpg",
		  "imageFit": "cover",
		  "imageHeight": "auto",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question45",
		  "html": "<h4>ASH - FIELD MAPLE – DOG’S MERCURY WOODLAND</h4>\n\n<table>\n<tr><td><b>NVC number</b></td><td>W8</td></tr>\n<tr><td><b>NVC name</b></td><td> Lowland mixed broadleaved woodland with dog's mercury </td></tr>\n<tr><td><b>Soil type</b></td><td> Rendzinas and calcareous brown earths, base rich.</td></tr>\n<tr><td><b>Description</b></td><td>Ash/oak plus lime, hornbeam, elm. Hazel, maple, dogwood, spindle common. Abundant dog's mercury, wood anemone, tufted hair-grass, ivy, ramsons, and/or bluebells.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 2' or {question39} = '') and {question38} = 'lowland'"
	   },
	   {
					"type": "panel",
					"name": "panel17",
					"elements": [
		 {
		  "type": "image",
		  "name": "question46",
		  "imageLink": "/nvc/w17.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question47",
		  "html": "<h4>SESSILE OAK – DOWNY BIRCH – MOSS WOODLAND</h4>\n\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W17</td></tr>\n<tr><td><b>NVC name</b></td><td> Upland oak-birch woodland with bilberry </td></tr>\n<tr><td><b>Soil type</b></td><td> Podzolic and iron-pan soils, acidic</td></tr>\n<tr><td><b>Description</b></td><td>Birch/oak with rowan and hazel. Moss rich ground flora, few vascular plants.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 1' or {question39} = '') and {question38} = 'upland'"
	   },
	   {
					"type": "panel",
					"name": "panel18",
					"elements": [
		 {
		  "type": "image",
		  "name": "question48",
		  "imageLink": "/nvc/w11.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question49",
		  "html": "<h4>SESSILE OAK – DOWNY BIRCH – WOOD SORREL WOODLAND</h4>\n\n\n<table>\n<tr><td><b>NVC number</b></td><td>W11</td></tr>\n<tr><td><b>NVC name</b></td><td> Upland oak-birch woodland with bluebell </td></tr>\n<tr><td><b>Soil type</b></td><td>Base-poor brown earths </td></tr>\n<tr><td><b>Description</b></td><td>Birch/oak/rowan/hazel. Moss-rich ground flora plus wood sorrel, sweet vernal-grass, heath bedstraw, bracken, tormentil</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 3' or {question39} = '') and {question38} = 'upland'"
	   },
	   {
					"type": "panel",
					"name": "panel19",
					"elements": [
		 {
		  "type": "image",
		  "name": "question50",
		  "imageLink": "/nvc/w9.jpg",
		  "imageFit": "cover",
		  "imageHeight": "300",
		  "imageWidth": "100%"
		 },
		 {
		  "type": "html",
		  "name": "question51",
		  "html": "<h4>ASH – ROWAN – DOG’S MERCURY WOODLAND</h4>\n<table>\n<tr><td><b>NVC number</b></td><td>W9</td></tr>\n<tr><td><b>NVC name</b></td><td>Upland mixed broadleaved woodland with dog's mercury</td></tr>\n<tr><td><b>Soil type</b></td><td> Rendzinas and calcareous brown earths, base rich</td></tr>\n<tr><td><b>Description</b></td><td>Ash/rowan/birch, some sessile oak, hazel, elm. Varied ground flora, wood sorrel, common dogviolet, lady fern and base-rich herbs. Abundant mosses.</td></tr>\n</table>" +
		  "<p class=\"py-2\">If you agree with this assessment, please exit the NVC key and select confirm that there is a recognisable NVC community.</p>"
		 }
					],
					"visibleIf": "{question35} = 'uncommon' and {question1} = false and {question24} = 'uncommon' and {question32} = 'uncommon' and ({question39} = 'Item 2' or {question39} = '') and {question38} = 'upland'"
	   },
	   {
					"type": "html",
					"name": "question52",
					"html": "<p>Further reading: \n<ul>\n<li><a href=\"https://hub.jncc.gov.uk/assets/673dc337-e58f-4f6b-ac7b-717001983c2e\" target=\"_blank\">NVC field guide to woodland vegetation communities</a></li>\n<li><a href=\"https://core.ac.uk/download/pdf/4149223.pdf\" target=\"_blank\">Glaves et al 2009 report summarising AWVP usage in UK</a></li>\n<li><a href=\"https://www.field-studies-council.org/shop/publications/ancient-woodland-indicators-guide/\" target=\"_blank\">FSC field chart</a></li><li><a href=\"https://www.woodlandtrust.org.uk/trees-woods-and-wildlife/british-trees/a-z-of-british-trees/yew/\">Woodland Trust A-Z of British trees</a></li>\n</ul>\n</p>\n<span class=\\\"me-2 btn\\\"></span>" +
						"<small>Image credit: KJ Kirby, Woodland variation and the use of the National Vegetation Classification woodland section, 2011</small>"
	   }
	  ]
	 }
	],

	"navigateToUrl": "#",
	"navigateToUrlOnCondition": [
	 {}
	],

	// "showCompletedPage": false,
	"sendResultOnPageNext": true,
	"showTitle": false,
	// "showProgressBar": "bottom",
	// "showCompletedPage": true,
	"showPreviewPage": true,
	"checkErrorsMode": "onComplete",
	"pageNextText": "Save & continue",
	"completeText": "Save",
	"showPreviewBeforeComplete": "showAllQuestions",
	"widthMode": "responsive",
	"fitToContainer": true,
	"showQuestionNumbers": "off",

	"previewText": "Save & preview",
	"completedHtml": "result",
	"showNavigationButtons": "none",
	"showPrevButton": false,
	"showCompletedPage": false,
	"focusFirstQuestionAutomatic": false,
	"focusOnFirstError": false
};
