<template>
	<div v-if="type=='update'" class="modal-footer">
		<UiButton type="button" :label="'Cancel'" :theme="'secondary'" data-bs-dismiss="modal" @click="onFormClear()"/>
		<UiButton type="submit" :label="trans('global.buttons.update')" :data-bs-dismiss="dataBsDismiss ? 'modal' : null" @click="doSubmit()"/>
	</div>
	<div v-else-if="type=='add'" class="modal-footer">
		<UiButton type="button" :label="'Cancel'" :theme="'secondary'" data-bs-dismiss="modal" @click="onFormClear()"/>
		<UiButton type="submit" :label="trans('global.buttons.add')" :data-bs-dismiss="dataBsDismiss ? 'modal' : null" @click="doSubmit()"/>
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import { trans } from "@/helpers/i18n";

	export default defineComponent({
		components: { UiButton },
		props: {
			type: {
				type: String,
				default: null
			},
			dataBsDismiss: {
				type: Boolean,
				default: true
			}
		},
		emits: ["clear", "submit"],
		setup (props, { emit }) {
			function onFormClear () {
				emit("clear");
			}
			function doSubmit () {
				emit("submit");
			}
			return {
				onFormClear,
				doSubmit,
				trans

			};
		}
	});
</script>
