<template>
	<!-- Section: Design Block -->
	<section class="bg-theme-500 bgrg overflow-hidden" :style="{ backgroundImage: 'url(' + imgPath + ')' }" >
		<div id="top" class="container px-4 py-5 px-lg-5 text-center text-lg-start">
			<div class="row gx-lg-5 align-items-center mb-5 bg-theme-50 rounded-2xl">
				<div class="col-md-6 mb-5 mb-lg-0" style="z-index: 10">
					<h1 class=" display-5 fw-bold ls-tight py-3" style="color: hsl(218, 81%, 15%)">
						Woodland Condition Assessment
					</h1>
					<div class="mb-4 opacity-70" style="color: hsl(218, 81%, 25%)">
						<p>
							The condition assessment has been developed and tested by members of the <b>England Woodland
								Biodiversity Group*</b> and <b><a href="https://www.forestresearch.gov.uk/" target="_blank">Forest Research</a></b> to enable owners to assess woodland condition in two standardised ways.
						</p>
						<ol>
							<li class="fw-bold">
								for a Woodland Condition Assessment (WCA) for sites with no statutory designation (e.g. not designated as a Site of Special Scientific Interest).
							</li>
							<li class="fw-bold">
								for a Woodland Condition Assessment for use with the Statutory Biodiversity Metric.
							</li>
						</ol>

						<p>
							For other designated woodland sites please contact <a href="https://www.gov.uk/government/organisations/natural-england" target="_blank">Natural England</a>
						</p>

						<p>Simply create an account and download the app to begin.</p>
						<p>
							<small>* Members of the England Woodland Biodiversity Group who have produced the Condition
								Assessment are: <a href="https://www.gov.uk/government/organisations/forestry-commission">Forestry Commission</a>,
								<a href="https://www.gov.uk/government/organisations/natural-england">Natural England</a>,
								and <a href="https://www.woodlandtrust.org.uk/">Woodland Trust</a>.</small>
						</p>

						<div class="text-center">
							<UiButton v-if="pwaStore.showRefresh" :label="'Update (App already installed)'" @click="pwaStore.updateSW(true)"/>
							<UiButton v-else-if="pwaStore.event" :label="'Install App'" @click="install"/>
							<!-- <button v-else class="dropdown-item disabled">
							<a class=" block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80">
								Latest version (or cannot update)
							</a>
						</button> -->
							<!-- <UiButton v-else class="btn btn-primary disabled" :label="'Cannot install (or already installed)'"/> -->

							<UiButton v-else class="btn btn-primary" :label="'Download instructions'" @click="router.push('/help')"/>

							<p class="mt-3">
								<a href="#about">More information below <i class="bi-chevron-down"/></a>
							</p>
						</div>
					</div>
				</div>

				<div class="col-md-6 mb-5 mb-lg-0 position-relative">
					<div class=" bg-theme-50 rounded-2xl">
						<div class="card-body px-4 py-4 px-lg-5 py-lg-5">
							<slot/>
						</div>
					</div>
				</div>

				<div class="col-md-12 mb-10 mb-lg-0" style="z-index: 10">
					<div class="mb-4 opacity-70" style="color: hsl(218, 81%, 25%)">
						<AboutLogos/>
					</div>
				</div>
			</div>
		</div>
		<!-- </section> -->

		<!-- <section class="bg-theme-500 bgrg overflow-hidden" :style="{ backgroundImage: 'url(' + imgPath + ')' }"> -->
		<div id="about" class="container px-4 py-5 px-lg-5 text-center text-lg-start">
			<div class="row gx-lg-5 align-items-center mb-5 bg-theme-50 rounded-2xl">
				<div class="col-md-12 mb-10 mb-lg-0" style="z-index: 10">
					<h1 class=" display-5 fw-bold ls-tight py-3" style="color: hsl(218, 81%, 15%)">
						About
					</h1>
					<div class="mb-4 opacity-70" style="color: hsl(218, 81%, 25%)">
						<AboutText>
							<p class="text-center p-3">
								<a href="#top"><UiButton :label="'Get started'"/></a>
							</p>
						</AboutText>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts" setup>
	// Explicitly import these so Vite sees them for the PWA. Then pick one at
	// random to use for the BG.
	import wood1 from "../../../images/ai_woodland1.jpg";
	import wood2 from "../../../images/ai_woodland2.jpg";
	import wood3 from "../../../images/ai_woodland3.jpg";
	import wood4 from "../../../images/ai_woodland4.jpg";
	import wood5 from "../../../images/ai_woodland5.jpg";
	import { usePwaStore } from "@/stores/pwa";
	import { useAuthStore } from "@/stores/auth";
	import UiButton from "@/views/components/input/UiButton.vue";
	import { onMounted } from "vue";
	import AboutText from "@/views/pages/private/info/aboutText.vue";
	import AboutLogos from "@/views/pages/private/info/aboutLogos.vue";
	import router from "@/router";

	const pwaStore = usePwaStore();
	const authStore = useAuthStore();
	const woodImages = [wood1, wood2, wood3, wood4, wood5];
	const imgPath = woodImages[Math.floor(Math.random() * 5)];

	onMounted(() => {
		if (!(authStore.user == null))
			router.push("/panel/dashboard");
	});

	function install () {
		if (pwaStore.event) {
			pwaStore.event.prompt();
		}
	}
</script>
