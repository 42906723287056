<template>
	<div v-if="client.deleted_at" class="d-none"/>
	<div v-else :id="'card_'+client.id" class="col-xl-3 col-sm-6 col-12 g-4 card-group">
		<div class="card">
			<router-link
				:to="{name:'clientInfo',params:{ clientId: client.id }}"
				class="card-body inactive link-underline link-underline-opacity-0" type="button"
			>
				<!-- todo: inactive ^ ? -->
				<div class="media d-flex">
					<div class="media-body text-left">
						<h3 ><i class="bi-person-vcard me-2"/>{{ client.name }}</h3>
					</div>
				</div>
			</router-link>
			<div class="card-footer" >
				<i class="bi-trash" type="button" @click.prevent="deleteClient(client)"/>
				&nbsp;<i class="bi-pencil" type="button" data-bs-toggle="modal" :data-bs-target="'#edit_'+client.id"/>
				<ModalClientUpdate :element-id="'edit_'+client.id" :client="client"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import axios from "@/plugins/axios";

	import ModalClientAdd from "@/views/pages/private/dashboard/partials/ModalClientAdd.vue";
	import ModalClientUpdate from "@/views/pages/private/dashboard/partials/ModalClientUpdate.vue";
	import { useClientStore } from "@/stores/client";
	import type { Client } from "@/types/client";

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const clientStore = useClientStore();

	const props = defineProps<{
		client:any;
	}>();

	async function deleteClient (client: Client) {
		if (confirm("Are you sure you want to to delete this client? All linked surveys will be deleted as well.")) {
			await clientStore.destroy(client);
		}
	}
</script>
