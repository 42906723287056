<template>
	<transition enter-active-class="transition ease-out duration-300" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
		<div :id="id" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
				<div class="modal-content">
					<div class="modal-header">
						<h1 id="exampleModalLabel" class="modal-title fs-5">
							{{ title }}
						</h1>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="onClose()"/>
					</div>
					<div class="modal-body m-8 inline-block align-middle w-full max-w-2xl transform transition-all">
						<div>
							<template v-if="$props.isLoading">
								<div class="pt-10 pb-6 text-center">
									<Spinner/>
								</div>
							</template>
							<slot v-else/>
						</div>
					</div>
					<div v-if="!hasForm">
						<!-- If this modal has a (old) form, add submit buttons -->
						<ModalFooter
							:type="type"
							:data-bs-dismiss="dataBsDismiss"
							@clear="onFormClear" @submit="doSubmit"/>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	import { defineComponent } from "vue";
	import Spinner from "@/views/components/icons/Spinner.vue";
	import { trans } from "@/helpers/i18n";
	import ModalFooter from "@/views/components/ModalFooter.vue";

	export default defineComponent({
		components: { Spinner, ModalFooter },
		props: {
			id: {
				type: String,
				default: "none"
			},
			type: {
				type: String,
				default: "simple"
			},
			title: {
				type: String,
				default: ""
			},
			isShowing: {
				type: Boolean,
				default: false
			},
			isLoading: {
				type: Boolean,
				default: false
			},
			showClose: {
				type: Boolean,
				default: true
			},
			hasForm: {
				type: Boolean,
				default: false
			},
			dataBsDismiss: {
				type: Boolean,
				default: true
			}
		},
		emits: ["update:isShowing", "close", "clear", "submit"],
		setup (props, { emit }) {
			function onClose () {
				emit("close");
			}
			function onFormClear () {
				emit("clear");
			}
			function doSubmit () {
				emit("submit");
			}
			return {
				onClose,
				onFormClear,
				doSubmit,
				trans

			};
		}
	});
</script>
