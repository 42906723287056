<template>
	<div>
		<div class="inline-flex items-center text-sm leading-6 font-medium rounded-md transition ease-in-out duration-150 cursor-not-allowed" disabled="">
			<div class="spinner-border text-success" role="status">
				<span class="visually-hidden">{{ text }}</span>
			</div>
			<p :class="{'block w-full': textNewLine}">
				{{ text }}
			</p>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from "vue";
	import { trans } from "@/helpers/i18n";

	export default defineComponent({
		name: "Spinner",
		props: {
			text: {
				type: String,
				required: false,
				default: trans("global.phrases.loading")
			},
			textNewLine: {
				type: Boolean,
				required: false,
				default: true
			}
		}
	});
</script>
