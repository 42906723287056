<template>
	<UiForm id="register-form" @submit.prevent="onFormSubmit">
		<Alert class="mb-4"/>
		<TextInput v-model="form.first_name" type="text" :required="true" :label="trans('users.labels.first_name')" name="first_name" class="mb-2"/>
		<!-- <TextInput type="text" :label="trans('users.labels.middle_name')" name="middle_name" v-model="form.middle_name" class="mb-2 collapse"/> -->
		<TextInput v-model="form.last_name" type="text" :required="true" :label="trans('users.labels.last_name')" name="last_name" class="mb-2"/>
		<TextInput v-model="form.email" type="email" :required="true" :label="trans('users.labels.email')" name="email" class="mb-2"/>
		<TextInput v-model="form.password" type="password" :required="true" :label="trans('users.labels.password')" name="password" class="mb-2"/>
		<TextInput v-model="form.passwordConfirm" type="password" :required="true" :label="trans('users.labels.confirm_password')" name="password-confirm" class="mb-4"/>
		<hr>
		<RadioInput
			v-model="form.selectUseage"
			type="radio"
			:required="true"
			:label="trans('users.labels.select_useage')"
			name="useage-select"
			class="mb-4"
			:options="[{id:'owner', title:trans('global.phrases.owner_account_descriptor')},{id:'agent', title:trans('global.phrases.agent_account_descriptor')}]"
			@update:model-value="updateFormSelectUseage"/>
		<hr>
		<CheckboxInput
			v-model="form.agree_terms"
			type="radio"
			:required="true"
			:label="'Terms and conditions'"
			name="useage-select"
			class="mb-4"
			:options="[{id:'agree', title:'I agree to the terms and conditions', link:'/terms'}]"
			@update:model-value="updateFormAgreeTerms"/>
		<br>
		<div class="text-center">
			<UiButton type="submit" :label="trans('global.buttons.register')" @click="onFormSubmit"/>
		</div>
	</UiForm>
</template>

<script>
	import { reactive, defineComponent } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAuthStore } from "@/stores";
	import TextInput from "@/views/components/input/TextInput";
	import RadioInput from "@/views/components/input/RadioInput.vue";
	import CheckboxInput from "@/views/components/input/CheckboxInput.vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Alert from "@/views/components/Alert";
	import UiForm from "@/views/components/UiForm.vue";

	export default defineComponent({
		name: "RegisterForm",
		components: {
			UiForm,
			Alert,
			UiButton,
			TextInput,
			RadioInput,
			CheckboxInput
		},
		setup () {
			const authStore = useAuthStore();
			const form = reactive({
				first_name: null,
				middle_name: null,
				last_name: null,
				email: null,
				password: null,
				passwordConfirm: null,
				selectUseage: "",
				agree_terms: ""
			});

			function updateFormSelectUseage (value) {
				form.selectUseage = value.id;
			}

			function updateFormAgreeTerms (value, checked) {
				form.agree_terms = checked ? value.id : "";
				console.log(form.agree_terms);
			}

			function onFormSubmit () {
				console.log("form.selectUseage");
				console.log(form.selectUseage);
				const payload = {
					first_name: form.first_name,
					middle_name: form.middle_name,
					last_name: form.last_name,
					email: form.email,
					password: form.password,
					password_confirmation: form.passwordConfirm,
					type: form.selectUseage
				};

				authStore.register(payload);
			}

			return {
				onFormSubmit,
				form,
				trans,
				updateFormSelectUseage,
				updateFormAgreeTerms
			};
		}
	});
</script>
