import axios from "@/plugins/axios";
import type { AxiosInstance } from "axios";

export default abstract class BaseService {
	constructor () {
		this.url = "";
	}

	api!: AxiosInstance;
	url: string;

	setupAPI (baseURL: any) {
		this.api = axios;
		this.api.defaults.baseURL = baseURL;
		this.api.defaults.withCredentials = true;
	}

	protected post (url: any, data: any, config = {}) {
		return this.api.post(url, data, config);
	}

	protected put (url: any, data: any, config = {}) {
		if (data instanceof FormData) {
			data.append("_method", "PUT");
		} else {
			data._method = "PUT";
		}
		return this.api.post(url, data, config);
	}

	protected patch (url: any, data: any, config = {}) {
		if (data instanceof FormData) {
			data.append("_method", "PATCH");
		} else {
			data._method = "PATCH";
		}
		return this.api.post(url, data, config);
	}

	protected get (url: any, config = {}) {
		return this.api.get(url, config);
	}

	protected delete (url: any, config = {}) {
		return this.api.delete(url, config);
	}

	protected head (url: string): Promise<unknown> {
		return this.api.head(url);
	}
}

