<template>
	<Page>
		<template #body>
			<div class="w-full xl:w-2/3 ml-auto mr-auto">
				<Overview class="mb-4" />
				<FormGeneral class="mb-4"/>
				<FormPassword class="mb-4"/>
			</div>
		</template>
	</Page>
</template>

<script>
	import { defineComponent, ref } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAuthStore } from "@/stores/auth";
	import FormPassword from "@/views/pages/private/profile/partials/FormPassword";
	import FormGeneral from "@/views/pages/private/profile/partials/FormGeneral";
	import Overview from "@/views/pages/private/profile/partials/Overview";
	import Page from "@/views/layouts/Page";
	import Modal from "@/views/components/Modal";

	export default defineComponent({
		components: {
			// Modal,
			Page,
			Overview,
			FormGeneral,
			FormPassword
		},
		setup () {
			const store = useAuthStore();
			const isAvatarModalShowing = ref(false);

			function reloadAvatar () {
				store.getCurrentUser();
			}
			return {
				isAvatarModalShowing,
				reloadAvatar,
				trans
			};
		}
	});
</script>
