<template>
	<div v-if="estate.deleted_at" class="d-none"/>
	<div v-else :id="'card_'+estate.id" class="col-xl-3 col-sm-6 col-12 g-4 card-group">
		<div class="card">
			<router-link
				:to="{name:'estateInfo',params:{ clientId: clientId, estateId: estate.id }}"
				class="card-body inactive link-underline link-underline-opacity-0" type="button"
			>
				<div class="media d-flex">
					<div class="media-body text-left">
						<h3 ><i class="bi-map me-2"/>{{ estate.name }}</h3>
					</div>
				</div>
			</router-link>
			<div class="card-footer" >
				<i class="bi-trash" type="button" @click.prevent="deleteEstate(estate)"/>
				&nbsp;<i class="bi-pencil" type="button" data-bs-toggle="modal" :data-bs-target="'#edit_'+estate.id"/>
				<ModalEstateUpdate :element-id="'edit_'+estate.id" :estate="estate"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import axios from "@/plugins/axios";

	import ModalEstateAdd from "@/views/pages/private/dashboard/partials/ModalEstateAdd.vue";
	import ModalEstateUpdate from "@/views/pages/private/dashboard/partials/ModalEstateUpdate.vue";
	import { useEstateStore } from "@/stores/estate";
	import type { Estate } from "@/types/estate";

	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const estateStore = useEstateStore();

	const props = defineProps<{
		estate:any;
		clientId:any;
	}>();

	async function deleteEstate (estate: Estate) {
		estate.client_id = props.clientId;
		if (confirm("Are you sure you want to to delete this estate? All linked surveys will be deleted as well.")) {
			await estateStore.destroy(estate);
		}
	}
</script>
