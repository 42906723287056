<template>
	<Page :title="page.title" :breadcrumbs="page.breadcrumbs" :actions="page.actions" @action="onAction">
		<template #body>
			<Panel>
				<UiForm id="create-user" @submit.prevent="onSubmit">
					<TextInput v-model="form.first_name" class="mb-4" type="text" :required="true" name="first_name" :label="trans('users.labels.first_name')"/>
					<TextInput v-model="form.last_name" class="mb-4" type="text" :required="true" name="last_name" :label="trans('users.labels.last_name')"/>
					<!-- <TextInput class="mb-4" type="text" name="middle_name" v-model="form.middle_name" :label="trans('users.labels.middle_name')"/> -->
					<TextInput v-model="form.email" class="mb-4" type="email" :required="true" name="email" :label="trans('users.labels.email')"/>
					<Dropdown v-model="form.roles" class="mb-4" multiple="multiple" :server="'roles/search'" :server-per-page="15" :required="true" name="type" :label="trans('users.labels.roles')"/>
					<FileInput v-model="form.avatar" class="mb-4" name="avatar" accept="image/*" :label="trans('users.labels.avatar')" @click="form.avatar = ''"/>
					<TextInput v-model="form.password" class="mb-4" type="password" :required="true" name="password" :label="trans('users.labels.password')"/>
				</UiForm>
			</Panel>
		</template>
	</Page>
</template>

<script>
	import { defineComponent, reactive } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAuthStore } from "@/stores/auth";
	import TextInput from "@/views/components/input/TextInput";
	import Dropdown from "@/views/components/input/Dropdown";
	import Panel from "@/views/components/Panel";
	import Page from "@/views/layouts/Page";
	import FileInput from "@/views/components/input/FileInput";
	import UserService from "@/services/UserService";
	import { clearObject, reduceProperties } from "@/helpers/data";
	import { toUrl } from "@/helpers/routing";
	import UiForm from "@/views/components/UiForm.vue";

	export default defineComponent({
		components: { UiForm, FileInput, Panel, Dropdown, TextInput, Page },
		setup () {
			const { user } = useAuthStore();
			const form = reactive({
				first_name: "",
				last_name: "",
				middle_name: "",
				email: "",
				roles: [],
				avatar: "",
				password: ""
			});

			const page = reactive({
				id: "create_users",
				title: trans("global.pages.users_create"),
				filters: false,
				breadcrumbs: [
					{
						name: trans("global.pages.users"),
						to: toUrl("/users/list")

					},
					{
						name: trans("global.pages.users_create"),
						active: true
					}
				],
				actions: [
					{
						id: "back",
						name: trans("global.buttons.back"),
						icon: "bi-chevron-left",
						to: toUrl("/users/list"),
						theme: "outline"
					},
					{
						id: "submit",
						name: trans("global.buttons.save"),
						icon: "bi-save",
						type: "submit"
					}
				]
			});

			const service = new UserService();

			function onAction (data) {
				switch (data.action.id) {
				case "submit":
					onSubmit();
					break;
				}
			}

			function onSubmit () {
				service.handleCreate("create-user", reduceProperties(form, "roles", "id")).then(() => {
					clearObject(form);
				});
				return false;
			}

			return {
				trans,
				user,
				form,
				page,
				onSubmit,
				onAction
			};
		}
	});
</script>

<style scoped>

</style>
