import { defineStore } from "pinia";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { PlotSurvey } from "@/types/survey";
import Bugsnag from "@bugsnag/js";

/**
 * A store module for managing the woodland survey resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const usePlotSurveyStore = defineStore("plotSurvey", () => {
	/**
	 * Store a client by calling the API client store endpoint.
	 */
	const store = async function (plotSurvey: PlotSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		plotSurvey.created_at = isoString;

		// Update the current user store
		for (const client of authStore.user.clients) {
			for (const estate of client.estates) {
				for (const site of estate.sites)  {
					const woodlandSurvey = site.woodland_surveys.find((x: any) => x.id == plotSurvey.woodland_survey_id);
					if (woodlandSurvey) {
						woodlandSurvey.plot_surveys.push(plotSurvey);
						break;
					}
				}
			}
		}

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.post(route("api.plot_survey.store"), plotSurvey).then(()=>{
				console.log("added new plot survey");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Update a WoodlandSurvey by calling the API WoodlandSurvey update endpoint.
	 */
	const update = async function (plotSurvey: PlotSurvey): Promise<void> {
		const pwaStore = usePwaStore();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		plotSurvey.updated_at = isoString;

		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.patch(route("api.plot_survey.update", { id: plotSurvey.id }), plotSurvey).then(() => {
				console.log("Plot survey updated");
			}).catch(error=>{
				pwaStore.requireSync = true;
				if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Destroy a plotSurvey by calling the API plotSurvey destroy endpoint.
	 */
	const destroy = async function (plotSurvey: PlotSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		// Mark the plotSurvey as deleted
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		plotSurvey.deleted_at = isoString;
		plotSurvey.updated_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.plot_survey.destroy", { id: plotSurvey.id })).then(() => {
				console.log("deleted plotSurvey");
				// Remove local soft-deleted plotSurvey by refetching data
				authStore.getCurrentUser();
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Clients are persisted via the
		// current user store.
		paths: []
	}
});
