import { defineStore } from "pinia";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { WoodlandSurvey } from "@/types/survey";

/**
 * A store module for managing the woodland survey resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const useWoodlandSurveyStore = defineStore("woodlandSurvey", () => {
	/**
	 * Store a client by calling the API client store endpoint.
	 */
	const store = async function (woodlandSurvey: WoodlandSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		woodlandSurvey.created_at = isoString;

		// Update the current user store
		for (const client of authStore.user.clients) {
			for (const estate of client.estates) {
				const site = estate.sites.find((x: any) => x.id == woodlandSurvey.site_id);
				if (site) {
					site.woodland_surveys.push(woodlandSurvey);
					break;
				}
			}
		}

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.post(route("api.woodland_survey.store"), woodlandSurvey).then(()=>{
				console.log("added new woodland survey");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Update a WoodlandSurvey by calling the API WoodlandSurvey update endpoint.
	 */
	const update = async function (woodlandSurvey: WoodlandSurvey): Promise<void> {
		const pwaStore = usePwaStore();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		woodlandSurvey.updated_at = isoString;
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.patch(route("api.woodland_survey.update", { id: woodlandSurvey.id }), woodlandSurvey).then(() => {
				console.log("Woodland survey updated");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Destroy a WoodlandSurvey by calling the API WoodlandSurvey destroy endpoint.
	 */
	const destroy = async function (woodlandSurvey: WoodlandSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		// Mark the WoodlandSurvey as deleted
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		woodlandSurvey.deleted_at = isoString;
		woodlandSurvey.updated_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.woodland_survey.destroy", { id: woodlandSurvey.id })).then(() => {
				console.log("deleted WoodlandSurvey");
				// Remove local soft-deleted WoodlandSurveys by refetching data
				authStore.getCurrentUser();
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Clients are persisted via the
		// current user store.
		paths: []
	}
});

export const woodlandSurveyDefaults: Pick<WoodlandSurvey, "q01" | "q02" | "q03" | "q04" | "q05" | "q06" | "q07" | "q08" | "q09" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "resultWCA" | "resultBNG" | "n_plots_b" | "n_plots_c" | "n_plots_m" | "permit_full_share" | "permit_partial_share" | "xls_string"> = {

	q01: { b: 0, c: 0, m: 0 },
	q02: { b: 0, c: 0, m: 0 },
	q03: { b: 0, c: 0, m: 0 },
	q04: { b: 0, c: 0, m: 0 },
	q05: { b: 0, c: 0, m: 0 },
	q06: { b: 0, c: 0, m: 0 },
	q07: { b: 0, c: 0, m: 0 },
	q08: { b: 0, c: 0, m: 0 },
	q09: { b: 0, c: 0, m: 0 },
	q10: { b: 0, c: 0, m: 0 },
	q11: { b: 0, c: 0, m: 0 },
	q12: { b: 0, c: 0, m: 0 },
	q13: { b: 0, c: 0, m: 0 },
	q14: { b: 0, c: 0, m: 0 },
	q15: { b: 0, c: 0, m: 0 },
	resultWCA: { b: 0, c: 0, m: 0, advice_b: [], advice_c: [], advice_m: [], numQs: 15 },
	resultBNG: { b: 0, c: 0, m: 0, advice_b: [], advice_c: [], advice_m: [], numQs: 13 },
	n_plots_b: 0,
	n_plots_c: 0,
	n_plots_m: 0,
	permit_full_share: 1,
	permit_partial_share: 0,
	xls_string: ""
};
