<template>
	<Modal :id="elementId" :title="trans('users.labels.sync_error_title')" :has-form="false">
		<p>
			We just tried to synchronise your data with our server, but something went
			wrong. Our team has been automatically notified. Click OK to try again and
			if this problem continues, get in touch.
		</p>
		<div v-if="authStore.error">
			<p class="fw-bold">
				Additional info
			</p>
			<div class="border bg-light p-2 form-text">
				{{ authStore.error }}
			</div>
		</div>
		<div class="d-flex justify-content-end mt-3">
			<button class="btn btn-primary me-2" data-bs-dismiss="modal" @click="tryAgain">
				Try again
			</button>
			<button class="btn btn-primary" data-bs-dismiss="modal">
				Cancel
			</button>
		</div>
	</Modal>
</template>

<script lang="ts" setup>
	import { trans } from "@/helpers/i18n";
	import Modal from "@/views/components/Modal.vue";
	import { useAuthStore } from "@/stores/auth";

	const props = defineProps<{
		elementId: string;
	}>();

	const authStore = useAuthStore();

	function tryAgain () {
		authStore.syncCurrentUser();
	}
</script>
