<template>
	<Panel :title="trans('users.labels.about_you')">
		<div class="items-center">
			<div class="mb-4">
				<h6>{{ trans('users.labels.full_name') }}</h6>
				{{ user.full_name }}
			</div>

			<div class="mb-4">
				<h6>
					{{ trans('users.labels.email') }}
				</h6>
				<i class="bi-envelope"/> {{ user.email }}
				<Badge v-if="user.email_verified_at" theme="success" class="inline">
					{{ trans('users.status.verified') }}
				</Badge>
			</div>

			<div class="mb-4">
				<h6 class="mt-3">
					{{
						trans('global.phrases.member_since', {date: user.created_at.substring(0, 10)})
					}}
				</h6>
			</div>

			<div class="mt-4">
				<form v-if="!user.email_verified_at" class="inline-block ml-3" @submit.prevent="onVerificationSend">
					<UiButton type="submit" :label="trans('users.status.ask_verify')"/>
				</form>
			</div>
		</div>
	</Panel>
</template>

<script>
	import AuthService from "@/services/AuthService";
	import { getResponseError } from "@/helpers/api";

	import { trans } from "@/helpers/i18n";

	import { computed, defineComponent } from "vue";
	import { useAuthStore } from "@/stores/auth";
	import { useAlertStore } from "@/stores";
	import Avatar from "@/views/components/icons/Avatar";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Panel from "@/views/components/Panel";
	import Badge from "@/views/components/Badge";

	export default defineComponent({
		components: {
			Panel,
			Badge,
			// Avatar,
			UiButton
		},
		setup (props, { emit }) {
			const authService = new AuthService();
			const alertStore = useAlertStore();
			const { user } = useAuthStore();

			function onVerificationSend () {
				authService.sendVerification({ user: user.id })
					.then((response) => (alertStore.success(trans("global.phrases.verification_sent"))))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				user,
				onVerificationSend,
				trans
			};
		}
	});
</script>
