<template>
	<form :id="id" :class="classes" @submit.prevent="onFormSubmit">
		<template v-if="isElementLoading">
			<div class="pt-10 pb-6 text-center">
				<Spinner/>
			</div>
		</template>
		<!-- FIELDS -->
		<slot v-else/>
		<!-- BUTTONS -->
		<ModalFooter
			v-if="type=='add' || type=='update'"
			:type="type"
			:data-bs-dismiss="dataBsDismiss" @clear="onFormClear" @submit="doSubmit"/>
	</form>
</template>
<script>

	import { useGlobalStateStore } from "@/stores";
	import Spinner from "@/views/components/icons/Spinner";
	import { computed, defineComponent, watch } from "vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import ModalFooter from "@/views/components/ModalFooter.vue";

	import { trans } from "@/helpers/i18n";

	export default defineComponent({
		components: { Spinner, ModalFooter },
		props: {
			id: {
				type: String,
				default: ""
			},
			title: {
				type: String,
				default: ""
			},
			isLoading: {
				type: Boolean,
				default: false
			},
			classes: {
				type: String,
				default: null
			},
			type: {
				// add or update
				type: String,
				default: null
			},
			dataBsDismiss: {
				type: Boolean,
				default: false
			}
		},
		emits: ["update:isShowing", "clear", "submit"],
		setup (props, { emit }) {
			function onFormClear () {
				emit("clear");
			}
			function doSubmit () {
				emit("submit");
			}
			function onFormSubmit () {
				console.log("onformsubmit");
				emit("submit");
			}

			const isElementLoading = computed(() => {
				return useGlobalStateStore().loadingElements[props.id] || props.isLoading;
			});

			return {
				isElementLoading,
				onFormClear,
				doSubmit,
				trans
			};
		}
		// methods: {
		// 	doSubmit () {
		// 		emit("submit");
		// 	}
		// }
	});

</script>
