<template>
	<Panel :title="trans('users.labels.general_settings')">
		<!-- <form @submit.prevent="onFormSubmit"> -->
		<UiForm id="update-form" @submit.prevent="onFormSubmit">
			<TextInput v-model="form.first_name" type="text" :required="true" :label="trans('users.labels.first_name')" name="first_name" class="mb-4" />
			<TextInput v-model="form.last_name" type="text" :required="true" :label="trans('users.labels.last_name')" name="last_name" class="mb-4" />
			<TextInput v-model="form.email" type="email" :required="true" :label="trans('users.labels.email')" name="email" autocomplete="email" class="mb-4" />
			<RadioInput
				v-model="form.type" type="radio" :required="true" :model-value="form.type"
				:label="trans('users.labels.select_useage')" name="useage-select" class="mb-4"
				:options="[{ id: 'owner', title: trans('global.phrases.owner_account_descriptor') }, { id: 'agent', title: trans('global.phrases.agent_account_descriptor') }]"
				@update:model-value="updateFormSelectUseage"/>
			<br>
			<UiButton type="submit" :label="trans('global.buttons.update')" @click="onFormSubmit" />
		</UiForm>
	</Panel>
</template>

<script>
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { useAlertStore } from "@/stores";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import RadioInput from "@/views/components/input/RadioInput.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import Panel from "@/views/components/Panel";

	export default defineComponent({
		components: {
			Panel,
			UiButton,
			TextInput,
			RadioInput,
			UiForm
		},

		setup: function () {

			const authService = new AuthService();
			const alertStore = useAlertStore();
			const authStore = useAuthStore();
			const form = reactive({
				first_name: null,
				last_name: null,
				middle_name: null,
				email: null,
				type: null
			});

			onMounted(() => {
				if (!authStore.user) {
					return;
				}
				form.first_name = authStore.user.first_name;
				form.last_name = authStore.user.last_name;
				form.middle_name = authStore.user.middle_name;
				form.email = authStore.user.email;
				form.type = authStore.user.type;
			});

			function updateFormSelectUseage (value) {
				form.type = value.id;
			}

			function onFormSubmit () {
				authService.updateUser(form)
					.then(() => authStore.getCurrentUser())
					.then(() => console.log(authStore.user))
					.then((response) => (alertStore.success(trans("global.phrases.profile_updated"))))
					.catch((error) => (alertStore.error(getResponseError(error))));
			}

			return {
				onFormSubmit,
				form,
				trans,
				updateFormSelectUseage
			};
		}
	});
</script>
