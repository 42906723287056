<template>
	<Auth>
		<h2 class="font-bold text-2xl text-[#002D74]">
			{{ trans('global.pages.login') }}
		</h2>
		<p class="text-sm mt-4 mb-4 text-[#002D74]">
			<!-- {{ trans('global.phrases.login_desc') }} -->
		</p>
		<LoginForm/>
		<div class=" text-sm border-b border-[#002D74] py-4 text-[#002D74]">
			<router-link to="/forgot-password" class="text-sm base-link">
				{{ trans('global.phrases.forgot_password_ask') }}
			</router-link>
		</div>
		<hr>
		<div class="mt-3 text-sm py-4 text-[#002D74]">
			<p>{{ trans('global.phrases.register_ask') }}</p>
			<div class="text-center">
				<router-link to="/register">
					<UiButton :label="trans('global.buttons.register')"/>
				</router-link>
			</div>
		</div>
	</Auth>
</template>

<script lang="ts" setup>

	import UiButton from "@/views/components/input/UiButton.vue";

	import { default as LoginForm } from "@/views/pages/auth/login/Form.vue";

	import { trans } from "@/helpers/i18n";
	import Auth from "@/views/layouts/Auth.vue";
	import { onMounted, ref } from "vue";
	import { useAlertStore, useAuthStore } from "@/stores";
	const alertStore = useAlertStore();
	const authStore = useAuthStore();

	const message = ref("");

	const props = defineProps<{
		id: string,
		hash: string
	}>();
	onMounted(() => {
		// Access the query parameter 'message'
		message.value = route.message || "Email verified, log in as usual";
		alertStore.success(message.value);
	});

</script>
