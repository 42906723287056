import { defineStore } from "pinia";
import type { Estate } from "@/types/estate";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { Client } from "@/types/client";

/**
 * A store module for managing the Estate resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const useEstateStore = defineStore("estate", () => {
	/**
	 * Store an estate by calling the API estate store endpoint.
	 */
	const store = async function (estate: Estate): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		estate.created_at = isoString;

		// Update the current user store
		const client = authStore.user.clients.find((c: Client) => c.id == estate.client_id);
		client.estates.push(estate);

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.post(route("api.estate.store"), estate).then(() => {
				console.log("added new estate");
			}).catch(error => {
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Update an estate by calling the API estate update endpoint.
	 */
	const update = async function (estate: Estate): Promise<void> {
		const pwaStore = usePwaStore();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		estate.updated_at = isoString;

		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.patch(route("api.estate.update", { id: estate.id }), estate).then(() => {
				console.log("updated estate");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Destroy an estate by calling the API estate destroy endpoint.
	 */
	const destroy = async function (estate: Estate): Promise<void> {
		const pwaStore = usePwaStore();
		const authStore = useAuthStore();

		// Mark the estate as deleted
		const isoString = new Date().toISOString();
		estate.deleted_at = isoString;
		estate.updated_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.estate.destroy", { id: estate.id })).then(() => {
				console.log("deleted estate");
				// Remove local soft-deleted estates by refetching data
				authStore.getCurrentUser();
			}).catch(error => {
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Estates are persisted via the
		// current user store.
		paths: []
	}
});
