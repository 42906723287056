// import axios from "@/plugins/axios";
import axios from "axios";

import BaseService from "@/services/BaseService";
import { useGlobalStateStore } from "@/stores/global";

export default class AuthService extends BaseService {

	private globalStateStore;

	constructor () {
		super();
		this.url = "/";
		this.setupAPI(axios.defaults.baseURL);
		this.globalStateStore = useGlobalStateStore();
	}

	async getCsrfCookie () {
		return this.get(route("sanctum.csrf-cookie"));
	}

	/**
	 * Do a head request to see if the user is logged in or not.
	 * @returns void. We are interested in the status codes of the associated
	 * response: 401 for not logged in or 200 if logged in.
	 */
	async state (): Promise<void> {
		await this.head(route("api.user.index"));
		return;
	}

	async getCurrentUser () {
		await this.get("/sanctum/csrf-cookie");
		return this.get("/api/users/auth");
		// return this.get(route("api.users.auth"));
		// return await this.get(route("api.current-user.index"));
		// route("api.current-user.index"));
	}

	async forgotPassword (payload: any) {
		this.globalStateStore.loadingElements.forgotPasswordForm = true;
		return this.post(route("password.email"), payload).finally(() => {
			this.globalStateStore.loadingElements.forgotPasswordForm = false;
		});
	}

	async resetPassword (payload: any) {
		this.globalStateStore.loadingElements.resetPasswordForm = true;
		return this.post(route("password.update"), payload).finally(() => {
			this.globalStateStore.loadingElements.resetPasswordForm = false;
		});
	}

	async registerUser (payload: any) {
		this.globalStateStore.loadingElements.registerForm = true;
		return this.post("/register", payload).finally(() => {
			this.globalStateStore.loadingElements.registerForm = false;
		});
	}

	async updatePassword (payload: any) {
		this.globalStateStore.loadingElements.updatePasswordForm = true;
		return this.put("/user/password", payload).finally(() => {
			this.globalStateStore.loadingElements.updatePasswordForm = false;
		});
	}

	async sendVerification (payload: any) {
		console.log("sending verificaton link");
		this.globalStateStore.loadingElements.sendVerificationForm = true;
		return this.post("/email/verification-notification", payload).finally(() => {
			this.globalStateStore.loadingElements.sendVerificationForm = false;
		});
	}

	async updateUser (payload: any) {
		this.globalStateStore.loadingElements.updateProfileForm = true;
		return this.put("/user/profile-information", payload).finally(() => {
			this.globalStateStore.loadingElements.updateProfileForm = false;
		});
	}

	login (payload: any) {
		// Enable Laravel's 'remember me' functionality so that this client
		// can go offline, the session can expire, but it will then renew itself
		// when the client next connects.
		payload.remember = true;

		this.globalStateStore.loadingElements["loginForm"] = true;

		return axios.post("/login", payload).finally(() => {
			this.globalStateStore.loadingElements["loginForm"] = false;
		});
	}

	logout () {
		this.globalStateStore.loadingElements.logoutForm = true;
		return this.post(route("logout"), {}).finally(() => {
			this.globalStateStore.loadingElements.logoutForm = false;
		});
	}
}
