import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "@/router";
import i18n from "@/plugins/i18n";
import App from "@/App.vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { createPwa } from "./composables/createPwa";
import { surveyPlugin } from "survey-vue3-ui";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import OpenLayersMap, { type Vue3OpenlayersGlobalOptions } from "vue3-openlayers";
import { VueMasonryPlugin } from "vue-masonry";
import MasonryWall from "@yeger/vue-masonry-wall";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../css/app.css";

const app = createApp(App);

app.use(surveyPlugin);
app.use(router);
app.use(i18n);
app.use(VueMasonryPlugin);
app.use(MasonryWall);

// Set up Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

// Set up OpenLayers
const options: Vue3OpenlayersGlobalOptions = {
	debug: false
};
app.use(OpenLayersMap, options);

// Set up the PWA. Needs to run after Pinia.
createPwa();

// Set up bugsnag. Use one session for the app instance by turning off autoTrack.
// BugSnag's default would be to create a session for every vue-router change,
// which is fine unless we are offline in which case it generates lots of failed
// requests. BugSnag sessions are for measuring reliability so a single session
// for the entire app is arguably more relevant for us anyway.
const key = import.meta.env.VITE_BUGSNAG_KEY;
if (key) {
	Bugsnag.start({
		apiKey: key,
		plugins: [new BugsnagPluginVue()],
		autoTrackSessions: false
	});
	const bugsnagVue = Bugsnag.getPlugin("vue");
	if (bugsnagVue) app.use(bugsnagVue);
	Bugsnag.startSession();
}

// Go!
app.mount("#app");

