<template>
	<h2 :id="'heading_'+index" class="accordion-header">
		<button class="accordion-button" :class="props.collapsed ? 'collapsed' : ''" type="button" data-bs-toggle="collapse" aria-expanded="true" :data-bs-target="'#collapse_'+activeSurvey.id" :aria-controls="'collapse_'+activeSurvey.id">
			Plot {{ index + 1 }} {{ JSON.parse(activeSurvey.survey_data).location_descriptor ? " - " + JSON.parse(activeSurvey.survey_data).location_descriptor : "" }}
		</button>
	</h2>
	<div v-show="(activeSurvey?.deleted==null)" :id="'collapse_'+activeSurvey.id" class="accordion-collapse p-0" :class="props.collapsed ? 'collapse' : ''" :aria-labelledby="'heading_'+activeSurvey.id" data-bs-parent="#plotAccordion">
		<div class="accordion-body p-0">
			<a class="m-2 btn btn-warning" type="button" @click.prevent="deletePlot(activeSurvey)">
				Delete Plot<i class="bi-trash ms-2" />
			</a>
			<br>
			<slot/>
		</div>
	</div>
</template>

<script lang="ts" setup>
	/** WCA Root Component */
	import * as SurveyCore from "survey-core";
	// @ts-ignore
	import { nouislider } from "surveyjs-widgets";
	import "nouislider/dist/nouislider.css";
	nouislider(SurveyCore);

	import Inputmask from "inputmask";
	// @ts-ignore
	import { inputmask } from "surveyjs-widgets";
	inputmask(SurveyCore);

	import "survey-core/defaultV2.min.css";
	import { StylesManager } from "survey-core";
	import { useAuthStore } from "@/stores/auth";
	import axios from "@/plugins/axios";
	import { usePwaStore } from "@/stores/pwa";
	import { usePlotSurveyStore } from "@/stores/plot";

	StylesManager.applyTheme("bootstrap");

	const authStore = useAuthStore();
	// const alertStore = useAlertStore();
	const pwaStore = usePwaStore();
	const plotSurveyStore = usePlotSurveyStore();

	const props = defineProps<{
		surveyJson: any;
		parentSurvey: any;
		activeSurvey: any | null;
		index: number;
		collapsed: boolean | null;
	}>();

	async function deletePlot (plot_survey:any) {

		if (confirm("Are you sure you want to to delete this plot?")) {
			plotSurveyStore.destroy(props.activeSurvey);
		}
	}
</script>

<style>
/* .accordion-button:hover {
  background-color: #6ed4c09d;
}
.accordion-button {
  background-color: #6ED4BF;
  background: #6ED4BF
}
.accordion-button:not(.collapsed) {
    background-color: #6ed4bf;
}
.accordion-button:not(.collapsed):hover {
    background-color: #6ed4c09d;
} */
.noUi-origin{
	width:10%;
}
</style>
