import type { LoadingElements } from "@/types";
import type { GlobalStateStore } from "../types/stores";
import { defineStore } from "pinia";

export const useGlobalStateStore = defineStore({
	id: "global_state",
	state: (): GlobalStateStore => {
		return {
			loadingElements: {
				forgotPasswordForm: false,
				resetPasswordForm: false,
				updatePasswordForm: false,
				registerForm: false,
				sendVerificationForm: false,
				updateProfileForm: false,
				loginForm: false,
				logoutForm: false,
				plotForm: false
			},
			isUILoading: false
		};
	},
	actions: {
		setUILoading (isLoading: any) {
			this.isUILoading = isLoading;
		},
		isUILoading () {
			return this.isUILoading;
		}
		// setElementLoading (element: any, isLoading: boolean) {
		// 	if (!element || !element.length) {
		// 		return;
		// 	}
		// 	this.loadingElements[element] = isLoading;
		// 	this.setUILoading(isLoading);
		// }
	},
	persist: true
});
