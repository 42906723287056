<template>
	<Modal
		:id="elementId"
		:title="trans('users.labels.add_client')"
		:has-form="true"
		style="z-index:3000"
		@submit="onFormSubmit()"
		@clear="onFormClear"
		@close="onFormClear">
		<UiForm
			:type="'add'"
			:data-bs-dismiss="formValidated"
			@submit="onFormSubmit()"
			@clear="onFormClear"
			@close="onFormClear">
			<TextInput v-model="form.name" type="text" :required="true" :label="'Client ' + trans('users.labels.name')" name="name" class="mb-2"/>
		</UiForm>
	</Modal>
</template>

<script lang="ts" setup>
	// import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import router from "@/router";
	import { defineComponent, reactive, onMounted, computed } from "vue";
	// import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	// import { useAlertStore } from "@/stores";
	import { usePwaStore } from "@/stores/pwa";
	// import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { v4 as uuidv4 } from "uuid";
	import axios from "@/plugins/axios";
	import UiButton from "@/views/components/input/UiButton.vue";
	import { useClientStore } from "@/stores/client";
	import type { Client } from "@/types/client";

	// const authService = new AuthService();
	// const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const clientStore = useClientStore();

	const form = reactive({
		name: ""
	});

	const props = defineProps<{
		elementId:string;
	}>();

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = "";
	}

	function validateForm () {
		return (!(form.name == ""));
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	async function onFormSubmit () {
		if (formValidated.value) {
			const authStore = useAuthStore();

			const client: Client = {
				name: form.name,
				id: uuidv4(),
				estates: [],
				created_at: new Date().toISOString().replace("T", " "),
				updated_at: new Date().toISOString().replace("T", " "),
				user_id: authStore.user.id
			};

			await clientStore.store(client);

			onFormClear();

			router.push("/client/" + client.id);
		}

	}

</script>
