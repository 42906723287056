<template>
	<i class="" :class="'bi-' + props.name + ' ' + props.class"/>
</template>

<script>

	import { defineComponent } from "vue";

	export default defineComponent({
		name: "Icon",
		props: {
			name: {
				type: String,
				default: ""
			},
			class: {
				type: String,
				default: ""
			}
		},
		setup (props) {
			return {
				props
			};
		}
	});
</script>
