export function getResponseError (error, response) {
	const errorMessage = "API Error, please try again.";
	if (typeof error !== "object") {
		return errorMessage;
	}

	if (error.name === "Fetch User") {
		return error.message;
	}

	if (error.response?.data?.errors) {
		return error.response.data.errors;
	}

	if (error.response?.data?.message) {
		return error.response.data.message;
	}

	if (error.message) {
		return error.message;
	}

	if (!error.response) {
		console.error(`API ${error.config.url} not found`);
		return errorMessage;
	}
	if (process.env.NODE_ENV === "development") {
		console.error(error.response.data);
		console.error(error.response.status);
		console.error(error.response.headers);
	}

	return errorMessage;
}

export function prepareQuery (args) {
	let page = args.page || null;
	let search = args.search || null;
	let sort = args.sort || null;
	let filters = args.filters || null;
	let params = { page: page };
	if (search) {
		params.search = search;
	}
	if (sort) {
		if (sort.column && sort.direction) {
			params.sort_by = sort.column;
			params.sort = sort.direction;
		}
	}
	if (filters) {
		for (let key in filters) {
			if (!filters[key] || (Object.prototype.hasOwnProperty.call(filters[key], "value") && !filters[key].value)) {
				continue;
			}
			let comparison = Object.prototype.hasOwnProperty.call(filters[key], "comparison") ? filters[key].comparison : "=";
			let values = Array.isArray(filters[key].value) ? filters[key].value : [filters[key].value];
			let cleanValues = [];
			for (let i in values) {
				if ("object" === (typeof values[i])) {
					if (values[i].id) {
						cleanValues.push(values[i].id);
					}
				} else {
					cleanValues.push(values[i]);
				}
			}
			if (cleanValues.length > 0) {
				params["filters[" + key + "]"] = key + ";" + comparison + ";" + cleanValues.join("|");
			}
		}
	}

	return params;
}
