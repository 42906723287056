<template>
	<div :style="style" :class="$props.class">
		<label
			v-if="label"
			:for="name"
			class="text-sm text-gray-500"
			:class="{ 'sr-only': !showLabel }">
			{{ label }}<span v-if="$props.required" class="text-danger">*</span>
		</label>
		<input
			v-if="type == 'password'"
			:id="name"
			:type="type"
			:value="modelValue"
			:required="required"
			:disabled="disabled"
			:placeholder="placeholder"
			:autocomplete="autocomplete"
			class="form-control px-3 py-2 rounded-md shadow-sm border border-gray-300"
			@input="onInput">
		<input
			v-else-if="pattern"
			:id="name"
			type="text"
			:title="title"
			:value="modelValue"
			:required="required"
			:disabled="disabled"
			:placeholder="placeholder"
			:pattern="pattern"
			:autocomplete="autocomplete"
			class="form-control px-3 py-2 rounded-md shadow-sm border border-gray-300"
			@input="onInput">
		<input
			v-else-if="type == 'date'"
			:id="name"
			type="date"
			:title="title"
			:value="modelValue"
			:required="required"
			:disabled="disabled"
			:pattern="pattern"
			:autocomplete="autocomplete"
			class="form-control px-3 py-2 rounded-md shadow-sm border border-gray-300"
			@input="onInput">
		<input
			v-else-if="type !== 'textarea'"
			:id="name"
			:type="type"
			:value="modelValue"
			:required="required"
			:disabled="disabled"
			:placeholder="placeholder"
			:autocomplete="autocomplete"
			:maxlength="maxlength"
			class="form-control px-3 py-2 rounded-md shadow-sm border border-gray-300"
			@input="onInput">
		<textarea
			v-else
			:id="name"
			:value="modelValue"
			:required="required"
			:disabled="disabled"
			:placeholder="placeholder"
			:autocomplete="autocomplete"
			class="form-control px-3 py-2 rounded-md shadow-sm border border-gray-300"
			@input="onInput"/>
	</div>
</template>

<script>

	import { defineComponent } from "vue";

	export default defineComponent({
		inheritAttrs: false,
		props: {
			class: String,
			style: [String, Object],
			name: {
				type: String,
				required: true
			},
			label: {
				type: String,
				default: ""
			},
			modelValue: {
				default: "",
				type: [String, Number]
			},
			type: {
				type: String,
				default: "text"
			},
			showLabel: {
				type: Boolean,
				default: true
			},
			required: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: null
			},
			autocomplete: {
				type: String,
				default: null
			},
			pattern: {
				type: String,
				default: null
			},
			title: {
				type: String,
				default: null
			},
			maxlength: {
				type: Number,
				default: null
			}
		},
		emits: ["update:modelValue"],
		// setup (props, { emit }) {
		// function onInput (event) {
		// 	validateText(event);
		// 	if (!this.errors.textfield) { emit("update:modelValue", event.target.value); }
		// }
		// return {
		// 	onInput
		// };
		// },
		data () {
			return {
			};
		},
		methods: {
			onInput (event) {
				this.$emit("update:modelValue", event.target.value);
			}
		}
	});
</script>
