<template>
	<Auth>
		<h2 class="font-bold text-2xl text-[#002D74]">
			{{ trans('global.pages.register') }}
		</h2>
		<p class="text-sm mt-4 mb-4 text-[#002D74]">
			{{ trans('global.phrases.register_desc') }}
		</p>
		<RegisterForm/>
		<hr>
		<div class="mt-3 text-sm py-4 text-[#002D74]">
			<p>{{ trans('global.phrases.login_ask') }}</p>
			<div class="text-center">
				<router-link to="/login">
					<UiButton :label="trans('global.buttons.login')"/>
				</router-link>
			</div>
		</div>
	</Auth>
</template>

<script>
	import { default as RegisterForm } from "@/views/pages/auth/register/Form";
	import UiButton from "@/views/components/input/UiButton.vue";
	import { trans } from "@/helpers/i18n";
	import Auth from "@/views/layouts/Auth";

	export default {
		name: "RegisterView",
		components: {
			UiButton,
			Auth,
			RegisterForm
		},
		setup () {
			return {
				trans
			};
		}
	};
</script>
