import axios from "@/plugins/axios";
import BaseService from "@/services/BaseService";
import { useGlobalStateStore } from "@/stores/global";

export default class SurveyService extends BaseService {

	private globalStateStore;

	constructor () {
		super();
		this.url = "/";
		this.setupAPI(axios.defaults.baseURL);
		this.globalStateStore = useGlobalStateStore();
	}

	index () {
		return this.get(route("api.woodland-surveys.index"));
	}

	save (payload: any) {
		//         return this.post("/dashboard", payload).finally(() => {
		//             this.globalStateStore.loadingElements['login-form'] = false;
		//         });
	}

	submit () {
		//         return this.post("/dashboard", {}, {}).finally(() => {
		//             this.globalStateStore.loadingElements['logout-form'] = false;
		//         });
	}

}
