import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-Locale"] = Object.prototype.hasOwnProperty.call(localStorage, "locale") ? localStorage.locale : window.AppConfig.defaultLocale;
axios.defaults.headers.common["Content-Type"] = "application/json";
// All API requests are on the same server as the Vue app so a base URL of / works fine
// If this is set to a host and port from the .env then it might break Cypress,
// which runs inside Sail using localhost:80 whereas APP_URL and APP_PORT are only
// used to expose Sail to the host machine.
axios.defaults.baseURL = "/";

export default axios;
