import { createWebHistory, createRouter } from "vue-router";
import routes from "@/router/routes";
import { useAuthStore } from "@/stores/auth";

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	routes
});

router.beforeEach(async (to, from, next) => {
	const authStore = useAuthStore();

	const requiresAbility = to.meta.requiresAbility;
	const requiresAuth = to.meta.requiresAuth;
	const belongsToOwnerOnly = to.meta.isOwner;

	// Handle routes that requires auth AND have required abilities
	if (requiresAbility && requiresAuth) {
		if (authStore.hasAbilities(requiresAbility)) {
			next();
		} else {
			next({
				name: "login"
			});
		}
	}

	// Handle routes that are marked as belonging to owner
	// @todo Do we use this?
	else if (belongsToOwnerOnly) {
		if (authStore.user.is_owner) {
			next();
		} else {
			next({ name: "dashboard" });
		}
	}

	// Handle routes that are marked just as requires auth
	else if (requiresAuth) {
		if (authStore.user) {
			next();
		}  else {
			next({
				name: "login"
			});
		}
	}

	// All other routes
	else {
		next();
	}
});

export default router;
