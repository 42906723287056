import { defineStore } from "pinia";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { Client } from "@/types/client";

/**
 * A store module for managing the Client resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const useClientStore = defineStore("client", () => {
	/**
	 * Store a client by calling the API client store endpoint.
	 */
	const store = async function (client: Client): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		client.created_at = isoString;

		// Update the current user store
		authStore.user.clients.push(client);

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.post(route("api.client.store"), client).then(() => {
				console.log("added new client");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
		return;
	};

	/**
	 * Update a client by calling the API client update endpoint.
	 */
	const update = async function (client: Client): Promise<void> {
		const pwaStore = usePwaStore();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		client.updated_at = isoString;

		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.patch(route("api.client.update", { id: client.id }), client).then(() => {
				console.log("client updated");
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	/**
	 * Destroy client by calling the API client destroy endpoint.
	 */
	const destroy = async function (client: Client): Promise<void> {
		const pwaStore = usePwaStore();
		const authStore = useAuthStore();

		// Mark the client as deleted
		client.deleted_at = new Date().toISOString().replace("T", " ");
		client.updated_at = new Date().toISOString().replace("T", " ");

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.client.destroy", { id: client.id })).then(() => {
				console.log("deleted client");
				// Remove local soft-deleted clients by refetching data
				authStore.getCurrentUser();
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Clients are persisted via the
		// current user store.
		paths: []
	}
});
