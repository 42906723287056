<template>
	<div class="text-center rounded p-3" style=" background-color: white">
		<img src="/logos/fc_linear_col.jpg" class="m-3" alt="Forestry England" style="height:50px">
		<img src="/logos/NatEng_logo_New-Green.png" class="m-3" alt="Natural England" style="height:50px">
		<img src="/logos/woodland_trust.png" class="m-3" alt="Woodland Trust" style="height:50px">
		<img src="/logos/fsc-logo-tree-green-rgb.png" class="m-3" alt="Field Studies Council" style=" height:50px">
		<img src="/logos/FRlogo_linear_colour_white box.jpg" class="m-3" alt="Forest Research" style="height:50px">
		<img src="/logos/Sylva_logo_RGB_rectangular_large.jpg" class="m-3" alt="Sylva Foundation" style="height:50px">
	</div>
</template>

<script lang="ts" setup>

</script>

