<template>
	<div class="row">
		<div class="col-12 mb-2 text-end">
			<router-link :to="{name:'userAdd'}" class="btn btn-primary">
				Create
			</router-link>
		</div>
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<h4>User</h4>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th>ID</th>
									<th>Name</th>
									<!-- <th>Description</th> -->
									<th>Actions</th>
								</tr>
							</thead>
							<tbody v-if="users.length > 0">
								<tr v-for="(user,key) in users" :key="key">
									<td>{{ user.id }}</td>
									<td>{{ user.first_name }}</td>
									<!-- <td>{{ user.description }}</td> -->
									<td>
										<router-link :to="{name:'userEdit',params:{id:user.id}}" class="btn btn-success">
											Edit
										</router-link>
										<button type="button" class="btn btn-danger" @click="deleteUser(user.id)">
											Delete
										</button>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="4" align="center">
										No users Found.
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	import { useAuthStore } from "@/stores/auth";
	import axios from "@/plugins/axios";

	export default {
		name: "UserList",
		data () {
			return {
				users: []
			};
		},
		mounted () {
			this.getUsers();
		},
		methods: {
			async getUsers () {
				const authStore = useAuthStore();
				console.log(authStore.user);

				// const authStore = useAuthStore();
				// console.log("aksldhalksdj");
				// console.log(authStore?.user?.clients[0]?.estates[0]?.users);
				// this.users.push(authStore.user);

				await axios.get(route("api.user.index")).then(response=>{
					this.users = [response.data.data];
					console.log(response);
				}).catch(error=>{
					console.log(error);
					this.users = [];
				});
			},
			deleteUser (id) {
				if (confirm("Are you sure you want to to delete this user ?")) {
					this.axios.delete(route("api.user.destroy", { id: id })).then(response=>{
						this.getUsers();
					}).catch(error=>{
						console.log(error);
					});
				}
			}
		}
	};
</script>
