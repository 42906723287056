<template>
	<Modal
		:id="elementId"
		:title="trans('users.labels.add_estate')"
		:has-form="true"
		@submit="onFormSubmit" @clear="onFormClear" @close="onFormClear">
		<UiForm
			:type="'add'"
			:data-bs-dismiss="validateForm()"
			@submit="onFormSubmit()"
			@clear="onFormClear"
			@close="onFormClear">
			<TextInput v-model="form.name" type="text" :required="true" :label="'Property ' + trans('users.labels.name')" name="name" class="mb-2" :maxlength="254" />
		</UiForm>
	</Modal>
</template>

<script lang="ts" setup>
	import AuthService from "@/services/AuthService";
	import router from "@/router";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed } from "vue";
	// import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { useEstateStore } from "@/stores/estate";
	// import { useAlertStore } from "@/stores";
	import { usePwaStore } from "@/stores/pwa";
	import UiButton from "@/views/components/input/UiButton.vue";
	import TextInput from "@/views/components/input/TextInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { v4 as uuidv4 } from "uuid";
	import axios from "@/plugins/axios";
	import type { Estate } from "@/types/estate";
	import type { Client } from "@/types/client";

	const authService = new AuthService();
	// const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const estateStore = useEstateStore();

	const form = reactive({
		name: ""
	});

	const props = defineProps<{
		client: Client;
		elementId:string;
	}>();

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = "";
	}

	function validateForm () {
		return (!(form.name == ""));
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	async function onFormSubmit () {
		if (formValidated.value) {
			const authStore = useAuthStore();

			const estate: Estate = {
				name: form.name,
				id: uuidv4(),
				sites: [],
				created_at: new Date().toISOString().replace("T", " "),
				updated_at: new Date().toISOString().replace("T", " "),
				client_id: props.client.id
			};
			await estateStore.store(estate);
			onFormClear();

			router.push("/estate/" + estate.id + "/" + props.client.id);
		}

	}

</script>
